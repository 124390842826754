import React, { Component } from 'react'
import { connect } from 'react-redux';
import { opacityChange } from '../../store/reducers/opacitySlice';
import { storeUserDatabaseData } from '../../store/reducers/authSlice';
import API from '../../utils/API';
import PaymentModal from '../../components/Modals/PaymentModal';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon';
import { globals } from '../../config/globals';
import { toast } from 'react-toastify'
import { MembershipPageNotLoggedInText, MembershipPageLoggedInAndMember, MembershipPageLoggedInAndNotMember } from '../../config/globals';
import Moment from 'react-moment';
import { 
  Icon,
  bitcoinsvIcon, 
} from '../../config/icons';

class MembershipPage extends Component {
  state = {
    pageOverlayActive: false,
    showPaymentModal: false,
    transactionType: '',
    numberOfMembers: '',
    recentMembers: [],
    numberOfCycles: 0,
  }
  cancelToken = API.CancelToken.source();
  timeout = '';
  componentDidMount() {
    setTimeout(() => {
      this.props.opacityChange('addMembershipOpacity')
    }, 100)
    this.getNumberOfMembersAndRecents()
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled')
    clearInterval(this.timeout);
  };
  getNumberOfMembersAndRecents = () => {
    API.getNumberOfMembersAndRecents(this.cancelToken.token)
      .then(res => {
        const numberOfMembers = res.data.numberOfMembers
        const recentMembers = res.data.recentMembers
        this.setState({ numberOfMembers: numberOfMembers, recentMembers: recentMembers },
          () => {
            // Adding 'active' dataset to first element in the array.
            if (recentMembers.length > 0) {
              const firstIndexItem = document.getElementById(`carousel-item-0`)
              firstIndexItem.dataset.active = true
              this.setCarouselInterval()
            }
          })
      })
      .catch(err => {
        console.error(err);
      })
  }
  // Carousel
  setCarouselInterval = () => {
    this.timeout = setInterval(() => {
      this.cycleCarousel()
    }, 6000)
  }
  cycleCarousel = () => {
    // Users are unlikely to stay on the page for this many cycles, so we'll stop it at this point to cut down on 
    // people going on the page and leaving it still for hours. 
    if (this.state.numberOfCycles >= 19) {
      return clearInterval(this.timeout);
    }
    // Grab the carousel container so we can grab all its children and find the index
    let carouselContainer = document.querySelector(`[data-carousel]`)
    // Find the active slide, so we can remove the active dataset after we switch it
    const activeSlide = carouselContainer.querySelector('[data-active]')
    // Finds the index of the active slide, which we use to shuffle the slides
    let newIndex = [...carouselContainer.children].indexOf(activeSlide)
    // If it has reached the end of the array, we move back to the 0 index
    if (this.state.recentMembers.length === newIndex + 1) newIndex = 0
    // If it has not reached the end, we add 1 to the index
    else newIndex += 1
    this.setState({ numberOfCycles: this.state.numberOfCycles + 1 })
    // Updates the new slide's data-active to true, so it is displayed on the page.
    carouselContainer.children[newIndex].dataset.active = true
    delete activeSlide.dataset.active
  }
  purchaseMembership = (e) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    if (localStorageAuth) localStorageAuth = JSON.parse(localStorageAuth);
    // Frontend measure to protect against duplicate purchase or not logged in user.
    if (!localStorageAuth?.authToken || !this.props.auth?.id || this.props.userDatabaseData?.membership) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          globals.toastError(toast, 'Unable to purchase Membership');
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    };
    // If user is logged in and doesn't already have membership
    if (this.props.userDatabaseData && !this.props.userDatabaseData?.membership) {
      let membershipPrice = 0
      let membershipType = ''
      // Make sure proper transaction type
      if (![`PURCHASE_LIFETIME_MEMBERSHIP`, `PURCHASE_ONE_YEAR_MEMBERSHIP`].includes(this.state.transactionType)) {
        return (
          this.setState({ showPaymentModal: false }, () => {
            globals.toastError(toast, 'Unable to purchase Membership');
            setTimeout(() => {
              this.setState({ transactionType: '' })
            }, 400)
          })
        )
      }
      // Set values based upon transaction type
      if (this.state.transactionType === 'PURCHASE_LIFETIME_MEMBERSHIP') {
        membershipPrice = globals.lifeTimeMembershipPrice
        membershipType = 'lifetime'
      }
      if (this.state.transactionType === 'PURCHASE_ONE_YEAR_MEMBERSHIP') {
        membershipPrice = globals.oneYearMembershipPrice
        membershipType = 'oneYear'
      }
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: '', pageOverlayActive: true })
        }, 400)
      })
      // Call purchaseMembership function
      API.purchaseMembership(membershipPrice, membershipType)
        .then(res => {
          const userUpdate = res.data.userUpdate
          // Update user data
          const totalValueSent = userUpdate.totalValueSent
          const membership = userUpdate.membership
          const membershipPrice = userUpdate.membershipPrice
          this.props.storeUserDatabaseData({type: 'membershipUpdate', totalValueSent, membership, membershipPrice })
          globals.toastSuccess(toast, `You have successfully purchased Membership on ${globals.platformName}!`);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        })
        .catch(err => {
          console.error(err);
          globals.toastError(toast, err.response.data.message);
          setTimeout(() => {
            this.setState({ pageOverlayActive: false })
          }, 400)
        })
    }
  }
  togglePaymentModal = (e, typeOfTransaction) => {
    if (e) e.preventDefault();
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction })
        }, 400)
      })
    }
  };
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    this.props.opacityChange('removeMembershipOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  render() {
    const { membershipOpacity, userDatabaseData, auth } = this.props;
    return (
      <>
        <main className={`pb-3 mt-2 px-1 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`} id='main-content'>
          <div className={`w-full rounded-xl px-1 mb-3 first-text gradient-background-four shadow-effect-eight pb-3`}>
            <div className={`${membershipOpacity} transition-to-opacity`}>
              {/* Wrapping div for top */}
              <div className={`md:flex items-center text-center w-auto px-2 md:pr-0 mt-1`}>
                <div className={`md:w-3/4 mt-3 pt-2 md:pt-0`}>
                  <h2 className={`teko-font text-4xl logo-style eleventh-text -mb-1`}>Membership</h2>
                  <hr className={`hidden md:block border-brand-green border outsetBorder mr-6 mb-3 mt-2`} />
                </div>
                <div className={`md:w-1/4 md:px-4 md:-mt-2 -mt-2`}>
                  <hr className={`md:hidden border border-brand-green outsetBorder mr-0 md:mr-6 mb-4 mt-2`} />
                  {this.state.numberOfMembers.length === 0
                    ? <></>
                    : <>
                      <div className={`mx-auto w-max text-center text-sm rounded-xl transition gradient-background px-1`}>
                        <div className={`text-spin px-3 cursor-pointer underline text-underline-under font-bold logo-style`}>
                          Total Members
                        </div>
                        <div className={`-mt-2px`}>
                          {this.state.numberOfMembers}
                        </div>
                      </div>
                    </>
                  }
                </div>
              </div>
              {/* Content below the 1st row */}
              <div className={`pr-3 md:pr-0 pl-3 md:flex mt-3 md:mt-0`}>
                <div className={`md:w-3/4 my-auto md:pr-6`}>
                  {/* Logged in */}
                  {auth?.id && userDatabaseData
                    ? userDatabaseData?.membership
                      // Already a member and logged in
                      ? <>
                        <MembershipPageLoggedInAndMember />
                      </>
                      // Not a member, but logged in
                      :
                      <>
                        <MembershipPageLoggedInAndNotMember />
                        <hr className={`border-brand-green border outsetBorder mt-3 mb-6`} />
                        <div className={`flex justify-center sm:justify-between flex-wrap`}>


                          <button onClick={e => this.togglePaymentModal(e, 'PURCHASE_ONE_YEAR_MEMBERSHIP')}
                            className={`py-1 mx-2 mb-3 px-2 gradient-background-twelve first-text border-brand-green text-sm rounded-xl border font-header mb-1`}
                          >
                            Purchase 1-Year Membership (<span className={`fifth-text`}>${globals.oneYearMembershipPrice.toFixed(2)}</span>)
                          </button>
                          <button onClick={e => this.togglePaymentModal(e, 'PURCHASE_LIFETIME_MEMBERSHIP')}
                            className={`py-1 mx-2 px-2 mb-3 gradient-background-twelve first-text border-brand-green text-sm rounded-xl border font-header mb-1`}
                          >
                            Purchase LifeTime Membership (<span className={`fifth-text`}>${globals.lifeTimeMembershipPrice.toFixed(2)}</span>)
                          </button>
                        </div>
                      </>
                    // Not logged in
                    : <MembershipPageNotLoggedInText />
                  }
                  <hr className={`block md:hidden border-brand-green border outsetBorder -mx-1 mt-3 mb-1`} />
                </div>
                {this.state.recentMembers.length === 0
                  ? <></>
                  : <>
                    <div className={`w-full mx-auto md:w-1/4 md:-mt-5 text-center transition`}>
                      <div className={`font-bold mb-2 pt-2px logo-style`}>
                        Recent Members
                      </div>
                      <div data-carousel className={`carousel relative w-full flex justify-center h-40 -mb-2`}>
                        {this.state.recentMembers.map((item, index) => {
                          return (
                            <div key={index} id={`carousel-item-${index}`}
                              className={`rounded-xl mx-auto fit-content text-center slide w-48 p-3
                              first-text gradient-background transition`}
                            >
                              <div className={`flex`}>
                                <div className={`flex-1`}></div>
                                <div className='flex-1 -mx-20 justify-center'>
                                  <img width='70' height='70'
                                    alt="Avatar"
                                    src={item.userAvatarURL}
                                    className={`rounded-2xl mx-auto shadow-effect-six`}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = '/images/round-handcash-logo.png'
                                    }}
                                  >
                                  </img>
                                </div>
                                <div className={`flex-1`}>
                                  {/* Blockchain Payment */}
                                  {!item.blockchainPaymentData
                                    ? <></>
                                    :
                                    <>
                                      <div className={`flex justify-end -mt-2px -mr-2px`}>
                                        <a className={`cursor-pointer hover:text-brandGreen text-xs sm:text-base`}
                                          href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                                        >
                                          <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                        </a>
                                      </div>
                                    </>
                                  }
                                </div>
                              </div>
                              <button onClick={(e) => this.goToUserProfilePage(e, item.userHandle)}
                                className={`fifth-hover mt-1 hover:underline sm:text-base text-sm overflow-hidden`}
                              >
                                {item.userHandle}
                              </button>
                              <div className="px-1 text-xs">
                                <i><Moment fromNow>{item.createdAt}</Moment></i>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </>
                }
              </div>

            </div>
          </div>
        </main>
        {this.state.pageOverlayActive === true
          ? <OverlayLoadingIcon />
          : <></>
        }
        {this.props.auth?.id
          ?
          <PaymentModal
            showPaymentModal={this.state.showPaymentModal}
            toggleModal={this.togglePaymentModal}
            triggerPaymentFunction={this.purchaseMembership}
            auth={this.props.auth}
            transactionType={this.state.transactionType}
            userToPay={this.state.paramHandle}
            tipAmount={this.state.tipAmount}
            donationAmount={this.state.donationAmount}
          />
          : <></>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.handCashLogInData,
    userDatabaseData: state.auth.userDatabaseData,
    membershipOpacity: state.opacity.membershipOpacity
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipPage);