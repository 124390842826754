import React from 'react'
import { Controlled as ControlledEditor } from 'react-codemirror2';
import { 
  Icon,
  caretDown20Filled, 
} from '../../config/icons';

import 'codemirror/lib/codemirror.css';

import 'codemirror/theme/elegant.css';
import 'codemirror/theme/material-darker.css';
import 'codemirror/theme/tomorrow-night-bright.css';
import 'codemirror/theme/mdn-like.css';

import 'codemirror/mode/xml/xml';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/css/css';
import 'codemirror/mode/clike/clike';
import 'codemirror/mode/python/python';

import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/hint/javascript-hint';
import 'codemirror/addon/hint/show-hint.css';
import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/keymap/sublime';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/closetag';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldgutter.css';

const CodeEditor = props => {
  let { onSelectLanguage, onSelectCodeTheme } = props
  const LanguageMenu = (
    <div className={``}>
      <button onClick={(e) => onSelectLanguage(e, "JavaScript" )} 
      className={`w-full h-full gradient-background-six mb-px hover-rounded rounded-t`}>JavaScript</button>
      <button onClick={(e) => onSelectLanguage(e, "CSS" )} 
      className={`w-full h-full gradient-background-six my-px hover-rounded`}>CSS</button>
      <button onClick={(e) => onSelectLanguage(e, "HTML/XML" )} 
      className={`w-full h-full gradient-background-six my-px hover-rounded` }>HTML/XML</button>
      <button onClick={(e) => onSelectLanguage(e, "C, C++, C#" )} 
      className={`w-full h-full gradient-background-six my-px hover-rounded`}>C, C++, C#</button>
      <button onClick={(e) => onSelectLanguage(e, "Python" )} 
      className={`w-full h-full gradient-background-six mt-px hover-rounded rounded-b`}>Python</button>
    </div>
  );
  const ThemeMenu = (
    <div className={``}>
      <button onClick={(e) => onSelectCodeTheme(e, "elegant" )} 
      className={`w-full h-full gradient-background-six mb-px hover-rounded rounded-t`}>Bright</button>
      <button onClick={(e) => onSelectCodeTheme(e, "mdn-like" )} 
      className={`w-full h-full gradient-background-six my-px hover-rounded`}>Light</button>
      <button onClick={(e) => onSelectCodeTheme(e, "material-darker" )} 
      className={`w-full h-full gradient-background-six my-px hover-rounded`}>Dark</button>
      <button onClick={(e) => onSelectCodeTheme(e, "tomorrow-night-bright" )} 
      className={`w-full h-full gradient-background-six mt-px hover-rounded rounded-b`}>Night</button>
    </div>
  );
  return (
    <>
      <div className={`inline-flex flex-wrap`}>
        <div data-dropdown className={`dropdown`}>
            <button data-dropdown-button className={`font-header link shadow-effect-seven eighth-text
            gradient-background-six button-ripple transition rounded flex items-center
              py-px px-2 category-hover text-sm sm:mx-2 my-2`}>
              {props.languageDropdownValue}
              <div className="ml-px -mr-1 pointer-events-none"><Icon icon={caretDown20Filled} height={17} width={17} /></div>
            </button>
            <div className={`dropdown-menu text-sm font-header rounded`}>
              {LanguageMenu}
            </div>
        </div>
        <div data-dropdown className={`dropdown`}>
            <button data-dropdown-button className={`font-header link shadow-effect-seven gradient-background-six eighth-text
              button-ripple transition rounded flex items-center py-px px-2 category-hover text-sm mx-2 my-2`}>
              {props.themeDropdownValue}
              <div className="ml-px -mr-1 pointer-events-none"><Icon icon={caretDown20Filled} height={17} width={17} /></div>

            </button>
            <div className={`dropdown-menu right-theme text-sm font-header rounded`}>
              {ThemeMenu}
            </div>
        </div>
      </div>
      <div className={`sm:mx-2 mt-2 mb-6 shadow-effect-five`}>
        <ControlledEditor
          onBeforeChange={props.handleCodeEditorChange}
          value={props.codeEditorContent}
          options={{
            mode: props.codeEditorLanguage,
            theme: props.themeforCodeEditor,
            lineWrapping: true,
            smartIndent: true,
            lineNumbers: true,
            foldGutter: true,
            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            keyMap: "sublime",
            matchBrackets: true,
            autoCloseBrackets: true,
            lint: true,
            indentWithTabs: true,
            autoCloseTags: true,
            extraKeys: {
              "Ctrl-Space": "autocomplete"
            }
          }}
        />
      </div>
    </>
  )
}

export default CodeEditor;