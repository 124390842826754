import React, { Component } from 'react'
import Moment from 'react-moment';
import parse from 'html-react-parser';
import { connect } from 'react-redux'
import { toast } from 'react-toastify';
import { storeUserDatabaseData } from '../../store/reducers/authSlice'
// Icons
import { 
  Icon,
  bitcoinsvIcon,
  createOutline,
  thumbsUp,
  commentsIcon
} from '../../config/icons';

import AddComment from '../../components/Comments/AddComment';
import PaymentModal from '../../components/Modals/PaymentModal';
import ConfirmCancelModal from '../../components/Modals/ConfirmCancelModal';
import OverlayLoadingIcon from '../../components/Widgets/OverlayLoadingIcon';
import SkeletonLoaderForPosts from '../../components/Widgets/SkeletonLoaderForPosts';
import { globals, iframeUpdate } from '../../config/globals';
import API from '../../utils/API';

class SingleCommentInProfilePage extends Component {
  state = {
    commentOpacity: 'opacity-100',
    showCommentEditor: false,
    commentBody: '',
    commentBodyError: '',
    overallError: '',
    showPaymentModal: false,
    pageOverlayActive: false,
    nonPaymentOverlay: false,
    transactionType: '',
    showConfirmCancelModal: false,
  }

  componentDidMount() {
    setTimeout(() => {
      iframeUpdate()
    }, 300)
  }
  toggleConfirmCancelModal = (e) => {
    e.preventDefault();
    if (this.state.showConfirmCancelModal === false) {
      this.setState({ showConfirmCancelModal: true })
    }
    else this.setState({ showConfirmCancelModal: false })
  }
  toggleCommentEditor = (e) => {
    if (e) e.preventDefault()
    const comment = this.props.item
    // Confirm this is their own comment
    if (comment && this.props.auth.id !== comment.commentCreatorHandCashId) {
      return globals.toastError(toast, `Unable to edit other's comments!`);
    }
    // So I'll need to toggle a state so that the Editor appears and this particular comment spec disappears. View Comment, View Editor.
    if (this.state.showCommentEditor) {
      this.setState({ commentOpacity: 'opacity-0' })
      setTimeout(() => {
        this.setState({ showCommentEditor: false, commentOpacity: 'opacity-100', showConfirmCancelModal: false })
      }, 400)
    }
    else {
      this.setState({ commentOpacity: 'opacity-0' })
      setTimeout(() => {
        this.setState({ showCommentEditor: true, commentOpacity: 'opacity-100', commentBody: comment.body, showConfirmCancelModal: false })
      }, 400)
    }
  }
  handleCommentChange = (data) => {
    this.setState({
      commentBody: data
    })
    if (this.state.overallError === '') return
    setTimeout(() => {
      if (this.state.commentBody.length >= 1) {
        this.setState({ commentBodyError: '', overallError: '' })
      }
    }, 100)
  }
  validationChecks = () => {
    let allChecksValid;
    // commentBody Validation
    let commentBodyIsValid;
    if (this.state.commentBody.length <= 1) {
      this.setState({
        commentBodyError: '*Your comment could use some material*'
      })
      commentBodyIsValid = false
    } else commentBodyIsValid = true;

    // This allChecksValid will return true or false to initiate the function in handleSubmitPost
    if (!commentBodyIsValid) {
      allChecksValid = false
      this.setState({ overallError: `*Psst, fix any errors above and try again*` })
    }
    else allChecksValid = true;
    return allChecksValid;
  }
  toggleOverlay = () => {
    this.setState({ showPaymentModal: false }, () => {
      setTimeout(() => {
        this.setState({ transactionType: '', pageOverlayActive: true })
      }, 400)
    })
  }
  submitCommentUpdate = (e, comment) => {
    e.preventDefault();
    let localStorageAuth = localStorage.getItem('handCashAuthData');
    // If no auth
    if (!comment || !localStorageAuth || !this.props.auth) {
      return (
        globals.toastError(toast, `Missing information to update comment.`),
        this.setState({ showPaymentModal: false }),
        this.props.history.push(`/`)
      )
    }
    // If no changes have been made
    if (this.state.commentBody === comment.body) {
      return (
        globals.toastError(toast, `No changes have been made.`),
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        })
      )
    }
    // If it is not the user
    if (this.props.auth.id !== comment.commentCreatorHandCashId) {
      return (
        globals.toastError(toast, `Unable to edit other's comments!`),
        this.setState({ showPaymentModal: false }),
        this.props.history.push(`/`)
      )
    }
    // Comment validation measures
    const validationReturnsTrue = this.validationChecks()
    if (!validationReturnsTrue) {
      return (
        this.setState({ showPaymentModal: false }, () => {
          setTimeout(() => {
            this.setState({ transactionType: '' })
          }, 400)
        }),
        globals.toastError(toast, 'Fix error and try again')
      )
    }
    this.toggleOverlay()
    this.setState({ nonPaymentOverlay: true })
    // Replacing comment with new comment body and checking for iframe
    let newComment = {...comment}
    newComment.body = this.state.commentBody
    let hasIframeInComment = false
    if (newComment.body.includes('<iframe')) hasIframeInComment = true
    newComment.hasIframeInComment = hasIframeInComment
    // Need to remove empty paragraphs
    const checkIfEmptyParagraph = newComment.body.endsWith(`<p><br></p>`)
    if (checkIfEmptyParagraph) {
      do newComment.body = newComment.body.slice(0, -11)
      while (newComment.body.endsWith(`<p><br></p>`))
    }
    newComment.body = globals.checkForLinksToAdd(newComment.body)
    // updating comment
    API.submitCommentUpdate(newComment)
      .then(res => {
        let updatedComment = res.data
        const commentBody = updatedComment.body
        const commentIndexInArray = this.props.index;
        // Updating the comments array
        this.props.storeUserDatabaseData({ type: 'updateCommentsArray', commentIndexInArray, commentBody })
        // This ugly setTimeout sequence is needed because the pageOverlay doesn't close otherwise
        setTimeout(() => {
          this.setState({
            pageOverlayActive: false,
            commentOpacity: 'opacity-0',
            nonPaymentOverlay: false
          },
            // 2nd setTimeout function
            () => {
              setTimeout(() => {
                this.setState({
                  commentBody: '',
                  commentOpacity: 'opacity-100',
                  showCommentEditor: false,
                })
              }, 400)
            })
          iframeUpdate()
        }, 400)
        globals.toastSuccess(toast, `You have successfully updated your comment!`);
      })
      .catch(err => {
        console.error(err)
        this.setState({ pageOverlayActive: false, nonPaymentOverlay: false })
        globals.toastError(toast, err.response.data.message)
      })
  }
  togglePaymentModal = (e, typeOfTransaction) => {
    e.preventDefault();
    let validationReturnsTrue = this.validationChecks();
    if (!validationReturnsTrue) return globals.toastError(toast, 'Correct the error(s) and try again')
    if (this.state.showPaymentModal === false) {
      this.setState({ showPaymentModal: true, transactionType: typeOfTransaction })
    }
    else {
      this.setState({ showPaymentModal: false }, () => {
        setTimeout(() => {
          this.setState({ transactionType: typeOfTransaction })
        }, 400)
      })
    }
  };
  closeConfirmCancelModal = (e) => {
    e.preventDefault();
    this.setState({ showConfirmCancelModal: false })
  }

  render() {
    const {
      goToPost,
      index,
      item,
      auth,
      refCallbackForArray,
      paginatedCommentsAreLoaded,
      noMoreComments,
      userData
    } = this.props;
    return (
      <div key={index} className={`w-full rounded-xl ${this.state.commentOpacity} transition-to-opacity`}>
        <div className={`w-full rounded-xl first-text gradient-background-four shadow-effect-eight word-wrap transition ${this.state.showCommentEditor ? `` : `px-2  py-1`} mb-2`}>
          {!this.state.showCommentEditor
            ?
            <>
              {/* Top row */}
              <div className={`text-xs flex flex-wrap justify-between w-full items-center mb-1`}>
                {/* Left Corner */}
                <div className={`flex items-end word-wrap rounded flex-wrap mt-1`}>
                  {/* Post Link */}
                  {!item.postSlug || !item.postTitle
                    ? <></>
                    :
                    <button className={`text-lg eleventh-text fifth-hover hover:underline text-underline-under text-left mr-2`}
                      onClick={(e) => goToPost(e, item.postSlug)} >
                      {item.postTitle}
                    </button>
                  }
                </div>
                {/* Right Corner */}
                <div className={`flex text-medium-gray mt-1`}>
                  <i className='mr-1'><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></i>
                  {auth && item.commentCreatorHandCashId === auth.id
                    ?
                    <>
                      <button onClick={(e) => this.toggleCommentEditor(e, item)}
                        className={`sixth-hover -mt-1`}>
                        <Icon icon={createOutline} height={18} width={18} />
                      </button>
                    </>
                    : <></>
                  }
                </div>
              </div>
              {/* Divider */}
              <hr className={`sixth-border outsetBorder border mt-2 mb-1 opacity-50`}></hr>
              {/* CommentBody */}
              {/* Will need to add the +/- stuff to hide/shown when they go long. Like the description. */}
              <div className={`py-1 w-full word-wrap parsed-editor-data profile-comment`}>{parse(item.body)}</div>
              <hr className={`sixth-border outsetBorder border mt-1 mb-2 opacity-50`}></hr>
              {/* Bottom Wrapper */}
              <div className={`flex flex-wrap justify-between w-full text-xs text-medium-gray pb-1 -mt-2px`}>
                {/* Bottom Left */}
                <div className={`flex flex-wrap items-center`}>
                  {/* Amount earned */}
                  <div className={`fifth-text sm:hidden pointer-events-none mr-2 font-bold`}>
                    ${item.totalValueReceived}
                  </div>
                  <div className={`hidden sm:inline-block tooltip mr-2 fifth-text`} data-tooltip="Earned">
                    <span className={`font-bold pointer-events-none`}>${item.totalValueReceived}</span>
                  </div>
                  {/* Thumbs */}
                  <div className={`flex`} >
                    <div className={`sm:hidden inline-block mr-1 mt-2px`}>
                      <Icon icon={thumbsUp} height={11} />
                    </div>
                    <div className={`tooltip hidden sm:inline-block mr-1 mt-2px`} data-tooltip="Thumbs">
                      <Icon icon={thumbsUp} height={11} />
                    </div>
                    <div className={`mr-2 pointer-events-none`}>{item.numThumbs}</div>
                  </div>
                  {/* Comments */}
                  <div className={`flex`}>
                    <div className={`sm:hidden inline-block mr-1 mt-px`}>
                      <Icon icon={commentsIcon} height={14} />
                    </div>
                    <div className={`tooltip hidden sm:inline-block mr-1 mt-px`} data-tooltip="Comments">
                      <Icon icon={commentsIcon} height={14} />
                    </div>
                    <div className={`mr-1 pointer-events-none`}>{item.numChildComments}</div>
                  </div>
                  {/* Category */}
                  {!item.categoryDisplayName
                    ? <></>
                    :
                    <div className={`mr-1 text-medium-gray`}>
                      in #{item.categoryDisplayName}
                    </div>
                  }
                </div>
                {/* Bottom Right */}
                <div className={`items-center flex flex-wrap`}>
                  {!item.blockchainPaymentData
                    ? <></>
                    :
                    <>
                      <a className={`cursor-pointer hover:text-brandGreen text-xs sm:text-base first-text`}
                        href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                        rel="noopener noreferrer"
                        target="_blank"
                        aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                      >
                        <Icon icon={bitcoinsvIcon} height={18} width={18} />
                      </a>
                    </>
                  }
                </div>
              </div>
              {/* End Wrapper Div */}
            </>
            //////////////////////////////////////////////////////////
            // If showCommentEditor === true. Replace with Add Comment
            //////////////////////////////////////////////////////////
            :
            <>
              <div id={`add-comment-index-${index}`} className={`rounded-xl transition p-1 sm:px-4 my-1
                sixth-text`}
              >
                <AddComment
                  handleCommentChange={this.handleCommentChange}
                  togglePaymentModal={this.togglePaymentModal}
                  commentBody={this.state.commentBody}
                  toggleConfirmCancelModal={this.toggleConfirmCancelModal}
                  commentBodyError={this.state.commentBodyError}
                  overallError={this.state.overallError}
                  // updateNotCreateNew=true is for proper PaymentModal use
                  updateNotCreateNew={true}
                  label={item.categoryDisplayName === 'Lists' ? `List Entry` : `Comment Creation Station`}
                  userDatabaseData={userData}
                />
              </div>
            </>
          }
          {this.state.pageOverlayActive === true
            ? <OverlayLoadingIcon nonPaymentOverlay={this.state.nonPaymentOverlay} />
            : <></>
          }
          {this.props.auth?.id
            ?
            <>
              <PaymentModal
                showPaymentModal={this.state.showPaymentModal}
                toggleModal={this.togglePaymentModal}
                triggerPaymentFunction={this.submitCommentUpdate}
                auth={this.props.auth}
                transactionType={this.state.transactionType}
                comment={item}
              />
              <ConfirmCancelModal
                showConfirmCancelModal={this.state.showConfirmCancelModal}
                closeConfirmCancelModal={this.closeConfirmCancelModal}
                confirmCancel={this.toggleCommentEditor}
              />
            </>
            : <></>
          }
        </div>
        {item.ref
          ? <div ref={refCallbackForArray}></div>
          : <></>
        }
        {item.lastItem
          ? noMoreComments
            ?
            <div className={`rounded text-sm text-center mt-3 p-1 text-medium-gray-lighter bg-transparent`}>
              {globals.noContentToDisplay}
            </div>
            : paginatedCommentsAreLoaded
              ? <></>
              :
              <>
                <SkeletonLoaderForPosts removeAvatar={true} />
                <SkeletonLoaderForPosts removeAvatar={true} />
                <SkeletonLoaderForPosts removeAvatar={true} />
                <SkeletonLoaderForPosts removeAvatar={true} />
                <SkeletonLoaderForPosts removeAvatar={true} />
              </>
          : <></>
        }
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData))
  }
}
export default connect(null, mapDispatchToProps)(SingleCommentInProfilePage)
