import React, { Component } from 'react';
import MainPage from './MainPage';
import LoginModal from '../../components/Modals/LoginModal';
import CategoryModal from '../../components/Modals/CategoryModal';
import API from '../../utils/API';
import { globals } from '../../config/globals';
import { connect } from 'react-redux';
import { opacityChange } from '../../store/reducers/opacitySlice';
import { toast } from 'react-toastify';

class MainPageContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryName: '',
      categoryDisplayName: '',
      categoryTags: [],
      categorySettings: null,
      homePosts: [],
      followingPosts: [],
      allTimePosts: [],
      trendingPosts: [],
      newestPosts: [],
      // weekPosts: [],
      pageNumberHomePosts: 1,
      pageNumberFollowingPosts: 1,
      pageNumberAllTimePosts: 1,
      pageNumberTrendingPosts: 1,
      pageNumberNewestPosts: 1,
      // pageNumberWeekPosts: 1,
      noMoreResultsHomePosts: false,
      noMoreResultsFollowingPosts: false,
      noMoreResultsAllTimePosts: false,
      noMoreResultsTrendingPosts: false,
      noMoreResultsNewestPosts: false,
      // noMoreResultsWeekPosts: false,
      homePostsIsLoaded: false,
      followingPostsIsLoaded: false,
      allTimePostsIsLoaded: false,
      newestPostsIsLoaded: false,
      trendingPostsIsLoaded: false,
      // weekPostsIsLoaded: false,
      postsType: '',
      sortSelections: this.sortSelections,
      homeStartingLoaded: false,
      followingStartingLoaded: false,
      allTimeStartingLoaded: false,
      newestStartingLoaded: false,
      trendingStartingLoaded: false,
      // weekStartingLoaded: false,
      categoryIsLoaded: false,
      listOrPostView: 'postView',
      showLoginModal: false,
      // Category modal
      isCategorySelected: false,
      showCategoryModal: false,
      categorySelected: '',
    }
  }
  sortTypeArray = ['trending',
    // 'week', 
    'all-time', 'newest'
  ]
  fetchTwenty = globals.fetchTwenty
  cancelToken = API.CancelToken.source()
  observer = React.createRef()
  sortSelections = [
    {
      displayName: `Today's Best`,
      sortName: 'trending',
      selectedClass: ''
    },
    // {
    //   displayName: 'Past Week',
    //   sortName: 'week',
    //   selectedClass: ''
    // },
    {
      displayName: 'Best Ever',
      sortName: 'all-time',
      selectedClass: 'highlight-border'
    },
    {
      displayName: 'Newest',
      sortName: 'newest',
      selectedClass: ''
    },
  ]

  componentDidMount() {
    this.fetchCategoryData();
    this.selectedSort()
    this.checkViewType();
  }
  componentDidUpdate(prevProps) {
    // If the category changes, we need to reset the state and then fetch the new category data.
    if (this.props.match.params.categoryName !== prevProps.match.params.categoryName) {
      if (this.cancelToken) {
        this.cancelToken.cancel('Operation canceled')
        this.cancelToken = API.CancelToken.source()
      }
      this.selectedSort()
      this.resetState();
      this.fetchCategoryData();
    }
  }
  componentWillUnmount() {
    this.resetState();
    this.cancelToken.cancel('Operation canceled')
  }
  resetState = () => {
    this.setState({
      categoryIsLoaded: false,
      homePosts: [],
      followingPosts: [],
      allTimePosts: [],
      trendingPosts: [],
      newestPosts: [],
      // weekPosts: [],
      pageNumberHomePosts: 1,
      pageNumberFollowingPosts: 1,
      pageNumberAllTimePosts: 1,
      pageNumberTrendingPosts: 1,
      pageNumberNewestPosts: 1,
      // pageNumberWeekPosts: 1,
      homePostsIsLoaded: false,
      followingPostsIsLoaded: false,
      allTimePostsIsLoaded: false,
      newestPostsIsLoaded: false,
      trendingPostsIsLoaded: false,
      // weekPostsIsLoaded: false,
      noMoreResults: false,
      noMoreResultsHomePosts: false,
      noMoreResultsFollowingPosts: false,
      noMoreResultsAllTimePosts: false,
      noMoreResultsTrendingPosts: false,
      noMoreResultsNewestPosts: false,
      // noMoreResultsWeekPosts: false,
      homeStartingLoaded: false,
      followingStartingLoaded: false,
      allTimeStartingLoaded: false,
      newestStartingLoaded: false,
      trendingStartingLoaded: false,
      // weekStartingLoaded: false,
    })
  }
  // Checks to see what the viewType is for the user.
  checkViewType = () => {
    let localStorageViewType = localStorage.getItem('viewType')
    if (localStorageViewType === 'listView') {
      this.setState({ listOrPostView: 'listView', listViewSelected: 'highlight-border' })
    } else if (localStorageViewType === 'postView') {
      this.setState({ listOrPostView: 'postView', postViewSelected: 'highlight-border' })
    } else {
      localStorage.setItem('viewType', 'postView')
      this.setState({ listOrPostView: 'postView', postViewSelected: 'highlight-border' })
    }
  }
  setView = (e, viewType) => {
    // If it's already what the user clicked on, return.
    globals.createRipple(e)
    if (this.state.listOrPostView === 'listView' && viewType === 'listView') return
    if (this.state.listOrPostView === 'postView' && viewType === 'postView') return
    if (viewType === 'listView') {
      localStorage.setItem('viewType', viewType)
      this.setState({ listOrPostView: 'listView', postViewSelected: '', listViewSelected: 'highlight-border' })
    } else {
      localStorage.setItem('viewType', 'postView')
      this.setState({ listOrPostView: 'postView', postViewSelected: 'highlight-border', listViewSelected: '' })
    }
  }
  checkForMembership = () => {
    // If category isn't water-cooler, we just return to home page.
    if (this.props.match && this.props.match.params.categoryName !== 'water-cooler') return (
      globals.toastError(toast, `Redirecting to the home page.`),
      this.props.history.push(`/`)
    )
    // If they are not logged in
    if (!this.props.auth?.id) return (
      globals.toastError(toast, `Members only. Redirecting to the home page.`),
      this.props.history.push(`/`)
    )
    // If the userDatabase data exists in Redux already, and they are NOT a member.
    if (this.props.userDatabaseData && !this.props.userDatabaseData?.membership) return (
      globals.toastError(toast, `Members only. Redirecting to the home page.`),
      this.props.history.push(`/`)
    )
    // If userDatabaseData does exist and they are a member
    if (this.props.userDatabaseData && this.props.userDatabaseData?.membership) {
      return this.setStateForCategory(globals.waterCooler)
    }
    // If userDatabaseData doesn't exist yet, but they are logged in, and params are correct
    API.checkForMembership(this.cancelToken.token)
      .then(res => {
        if (res.data === true) {
          this.setStateForCategory(globals.waterCooler)
        }
        else return (
          globals.toastError(toast, `Members only. Redirecting to the home page.`),
          this.props.history.push(`/`)
        )
      })
      .catch(error => {
        console.error(error);
        this.props.history.push(`/`)
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Error retrieving data. Redirecting to home page');
      });
  }
  // This function has two steps:
  // [1] - It captures the category name.
  // [2] - If successful, we return the this.fetchPosts() function, which fetches posts based upon the category.
  fetchCategoryData() {
    const categoryName = (this.props.match && this.props.match.params.categoryName)
    if (categoryName) {
      // Here is where I need to check if it's one of the categoryNames within the global file.
      if (globals.categoryHrefs.includes(categoryName)) {
        switch (categoryName) {
          case 'all':
            this.setStateForCategory(globals.all)
            break;
            case 'communities':
            this.setStateForCategory(globals.communities)
            break;
            case 'prompt-games':
            this.setStateForCategory(globals.promptGames)
            break;
          case 'stamp-you':
            this.setStateForCategory(globals.stampYou)
            break;
          case 'meet-the-stampers':
            this.setStateForCategory(globals.meetTheStampers)
            break;
          // Entertainment +
          case 'gaming':
            this.setStateForCategory(globals.gaming)
            break;
          case 'music':
            this.setStateForCategory(globals.music)
            break;
          case 'podcasts':
            this.setStateForCategory(globals.podcasts)
            break;
          case 'teach':
            this.setStateForCategory(globals.teach)
            break;
          case 'comedy':
            this.setStateForCategory(globals.comedy)
            break;
          case 'sports':
            this.setStateForCategory(globals.sports)
            break;
          case 'fitness':
            this.setStateForCategory(globals.fitness)
            break;
          case 'cooking':
            this.setStateForCategory(globals.cooking)
            break;
          case 'talent-showcase':
            this.setStateForCategory(globals.talentShowcase)
            break;
          case 'animals':
            this.setStateForCategory(globals.animals)
            break;
          case 'art':
            this.setStateForCategory(globals.art)
            break;
          case 'beautiful-nature':
            this.setStateForCategory(globals.beautifulNature)
            break;
          case 'tiktok-playlist':
            this.setStateForCategory(globals.tiktokPlaylist)
            break;
          case 'panoramic-reels':
            this.setStateForCategory(globals.panoramicReels)
            break;
          // Professional
          case 'entrepreneurship':
            this.setStateForCategory(globals.entrepreneurship)
            break;
          case 'ama':
            this.setStateForCategory(globals.ama)
            break;
          case 'career':
            this.setStateForCategory(globals.career)
            break;
          case 'interviews':
            this.setStateForCategory(globals.interviews)
            break;
          case 'in-the-news':
            this.setStateForCategory(globals.inTheNews)
            break;
          case 'bitcoin-businesses':
            this.setStateForCategory(globals.bitcoinBusinesses)
            break;
          case 'promotions':
            this.setStateForCategory(globals.promotions)
            break;
          // Knowledge
          case 'tutorials':
            this.setStateForCategory(globals.tutorials)
            break;
          case 'articles':
            this.setStateForCategory(globals.articles)
            break;
          case 'chatgpt':
            this.setStateForCategory(globals.chatGPT)
            break;
            case 'science':
            this.setStateForCategory(globals.science)
            break;
          case 'reviews':
            this.setStateForCategory(globals.reviews)
            break;
          case 'life':
            this.setStateForCategory(globals.life)
            break;
          case 'health':
            this.setStateForCategory(globals.health)
            break;
          case 'code-share':
            this.setStateForCategory(globals.codeShare)
            break;
          case 'books':
            this.setStateForCategory(globals.books)
            break;
          // Bonus
          case 'bitcoin-stories':
            this.setStateForCategory(globals.bitcoinStories)
            break;
          case 'polls':
            this.setStateForCategory(globals.polls)
            break;
          case 'lists':
            this.setStateForCategory(globals.lists)
            break;
          case 'questions':
            this.setStateForCategory(globals.questions)
            break;
          case 'stories':
            this.setStateForCategory(globals.stories)
            break;
          case 'letter-forever':
            this.setStateForCategory(globals.letterForever)
            break;
          case 'poetry':
            this.setStateForCategory(globals.poetry)
            break;
          case 'bounties':
            this.setStateForCategory(globals.bounties)
            break;
          // Water Cooler
          case 'water-cooler':
            this.checkForMembership()
            break
          // No longer on frontend & default
          case 'bsv-news':
            this.setStateForCategory(globals.appsAndBusinessNews)
            break;
          case 'prompts':
            this.setStateForCategory(globals.prompts)
            break;
            case 'writing-games':
              this.setStateForCategory(globals.writingGames)
              break;
          default:
            this.props.history.push(`/`)
        }
      } else {
        globals.toastError(toast, `Category doesn't exist. Redirecting to the home page.`);
        this.props.history.push(`/`)
      }
    }
    // If it's on the home page, grab Writing Games.
    else this.setStateForCategory(globals.promptGames)
  }
  setStateForCategory = categoryObject => {
    // If they're logged in and on the home page, we want to grab their feed, rather than a category.
    if ((this.props.match.params.categoryName === undefined) && this.props.auth?.id) {
      return this.setState({ categoryIsLoaded: true, categoryName: '' }, () => {
        this.getInitialPostsForLoggedInUser();
        setTimeout(() => {
          this.props.opacityChange('addCategoryOpacity')
          this.props.opacityChange('addPostListOpacity')
        }, 100)
      })
    }
    this.setState({
      categoryName: categoryObject.href,
      categoryDisplayName: categoryObject.categoryName,
      categoryTags: categoryObject.tags,
      categorySettings: categoryObject.settings,
      homePostsIsLoaded: false,
      allTimePostsIsLoaded: false,
      newestPostsIsLoaded: false,
      trendingPostsIsLoaded: false,
      // weekPostsIsLoaded: false,
    },
      () => this.setState({ categoryIsLoaded: true }, () => {
        setTimeout(() => {
          this.props.opacityChange('addCategoryOpacity')
          this.props.opacityChange('addPostListOpacity')
        }, 100)
        this.getInitialPostsForCategoryPage()
      })
    )
  }
  getInitialPostsForCategoryPage = () => {
    // This is default sort type if there hasn't been a selection during this session
    let sortListType = 'all-time'
    let sortTypeStorage = localStorage.getItem('sortTypeSelection')
    if (sortTypeStorage && this.sortTypeArray.includes(sortTypeStorage)) {
      sortListType = sortTypeStorage
    }
    this.setState({ postsType: sortListType })
    // Trending
    if (sortListType === 'trending') {
      API.getTrendingPostsByCategory(this.state.categoryName, this.state.pageNumberTrendingPosts, this.cancelToken.token)
        .then(res => {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            if (res.data.length === 0) {
              this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: true, trendingStartingLoaded: true })
            }
            // This is the check if there are some results, but no more.
            else if (res.data.length < this.fetchTwenty) {
              const updatedArray = this.firstLoadUpdateArray(res.data)
              this.setState({ trendingPosts: updatedArray }, () => {
                this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: true, trendingStartingLoaded: true })
              })
            }
            else {
              const updatedArray = this.firstLoadUpdateArray(res.data)
              this.setState({ trendingPosts: updatedArray }, () => {
                this.setState({
                  noMoreResultsTrendingPosts: false,
                  trendingPostsIsLoaded: true,
                  trendingStartingLoaded: true,
                })
              })
            }
            setTimeout(() => {
              this.props.opacityChange('addPostListOpacity')
            }, 100)
          }, 300)
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to find content');
        });
    }
    // Week
    // if (sortListType === 'week') {
    //   API.getWeekPosts(this.state.categoryName, this.state.pageNumberWeekPosts, this.cancelToken.token)
    //     .then(res => {
    //       this.props.opacityChange('removePostListOpacity')
    //       setTimeout(() => {
    //         if (res.data.length === 0) {
    //           this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: true, weekStartingLoaded: true })
    //         }
    //         else if (res.data.length < this.fetchTwenty) {
    //           const updatedArray = this.firstLoadUpdateArray(res.data)
    //           this.setState({ weekPosts: updatedArray }, () => {
    //             this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: true, weekStartingLoaded: true })
    //           })
    //         }
    //         else {
    //           const updatedArray = this.firstLoadUpdateArray(res.data)
    //           this.setState({ weekPosts: updatedArray }, () => {
    //             this.setState({
    //               noMoreResultsWeekPosts: false,
    //               weekPostsIsLoaded: true,
    //               weekStartingLoaded: true
    //             })
    //           })
    //         }
    //         setTimeout(() => {
    //           this.props.opacityChange('addPostListOpacity')
    //         }, 100)
    //       }, 300)
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       if (error.message === 'Operation canceled') return
    //       globals.toastError(toast, 'Unable to find content');
    //     });
    // }
    // All Time
    if (sortListType === 'all-time') {
      API.getAllTimePosts(this.state.categoryName, this.state.pageNumberAllTimePosts, this.cancelToken.token)
        .then(res => {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            if (res.data.length === 0) {
              this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: true, allTimeStartingLoaded: true })
            }
            // This is the check if there are some results, but no more.
            else if (res.data.length < this.fetchTwenty) {
              const updatedArray = this.firstLoadUpdateArray(res.data)
              this.setState({ allTimePosts: updatedArray }, () => {
                this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: true, allTimeStartingLoaded: true })
              })
            }
            else {
              const updatedArray = this.firstLoadUpdateArray(res.data)
              this.setState({ allTimePosts: updatedArray }, () => {
                this.setState({
                  noMoreResultsAllTimePosts: false,
                  allTimePostsIsLoaded: true,
                  allTimeStartingLoaded: true,
                })
              })
            }
            setTimeout(() => {
              this.props.opacityChange('addPostListOpacity')
            }, 100)
          }, 300)
        })
        .catch(error => {
          console.error(error.message);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to find content');
        });
    }
    // Newest
    if (sortListType === 'newest') {
      API.getNewestPosts(this.state.categoryName, this.state.pageNumberNewestPosts, this.cancelToken.token)
        .then(res => {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            if (res.data.length === 0) {
              this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: true, newestStartingLoaded: true, })
            }
            // This is the check if there are some results, but no more.
            else if (res.data.length < this.fetchTwenty) {
              const updatedArray = this.firstLoadUpdateArray(res.data)
              this.setState({ newestPosts: updatedArray }, () => {
                this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: true, newestStartingLoaded: true })
              })
            }
            else {
              const updatedArray = this.firstLoadUpdateArray(res.data)
              this.setState({ newestPosts: updatedArray }, () => {
                this.setState({
                  noMoreResultsNewestPosts: false,
                  newestPostsIsLoaded: true,
                  newestStartingLoaded: true
                })
              })
            }
            setTimeout(() => {
              this.props.opacityChange('addPostListOpacity')
            }, 100)
          }, 300)
        })
        .catch(error => {
          console.error(error);
          if (error.message === 'Operation canceled') return
          globals.toastError(toast, 'Unable to find content');
        });
    }
  }
  getInitialPostsForHomePage = () => {
    this.setState({ postsType: globals.homePosts })
    API.getPostsForHomePage(this.state.pageNumberHomePosts, this.cancelToken.token)
      .then(res => {
        this.props.opacityChange('removePostListOpacity')
        setTimeout(() => {
          if (res.data.length === 0) {
            this.setState({ homePostsIsLoaded: true, noMoreResultsHomePosts: true, homeStartingLoaded: true })
          }
          else if (res.data.length < this.fetchTwenty) {
            const updatedArray = this.firstLoadUpdateArray(res.data)
            this.setState({ homePosts: updatedArray }, () => {
              this.setState({ noMoreResultsHomePosts: true, homePostsIsLoaded: true, homeStartingLoaded: true })
            })
          }
          else {
            // Not so sure I need this. I think I could do res.data[4]. Don't see why not.
            const updatedArray = this.firstLoadUpdateArray(res.data)
            this.setState({ homePosts: updatedArray }, () => {
              this.setState({
                noMoreResultsHomePosts: false,
                homePostsIsLoaded: true,
                homeStartingLoaded: true
              })
            })
          }
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
          }, 100)
        }, 300)
      })
      .catch(error => {
        console.error(error.response);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to find content');
      });
  }
  getInitialPostsForLoggedInUser = () => {
    this.setState({ postsType: globals.followingPosts })
    API.getFollowingPosts(this.state.pageNumberFollowingPosts, this.cancelToken.token)
      .then(res => {
        this.props.opacityChange('removePostListOpacity')
        setTimeout(() => {
          // If they do not follow anybody (empty array is returned), we go the home page route instead. This should never hit anymore.
          if (res.data.length === 0) return this.getInitialPostsForHomePage()
          // If array has less than the maximum amount
          else if (res.data.length < this.fetchTwenty) {
            const updatedArray = this.firstLoadUpdateArray(res.data)
            this.setState({ followingPosts: updatedArray }, () => {
              this.setState({ noMoreResultsFollowingPosts: true, followingPostsIsLoaded: true, followingStartingLoaded: true })
            })
          }
          else {
            // Not so sure I need this. I think I could do res.data[4]. Don't see why not.
            const updatedArray = this.firstLoadUpdateArray(res.data)
            this.setState({ followingPosts: updatedArray }, () => {
              this.setState({
                noMoreResultsFollowingPosts: false,
                followingPostsIsLoaded: true,
                followingStartingLoaded: true
              })
            })
          }
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
          }, 100)
        }, 300)
      })
      .catch(error => {
        console.error(error);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to find content');
      });
  }
  firstLoadUpdateArray = (posts) => {
    let addingToArray = posts;
    if (posts.length === this.fetchTwenty) {
      let itemFive = addingToArray[4];
      itemFive.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  selectedSort = () => {
    // I also need to remove all the other selected.
    let sortSelections = this.state.sortSelections;
    sortSelections.forEach(category => {
      category.selectedClass = '';
    })
    let sortListType = localStorage.getItem('sortTypeSelection')
    if (sortListType && this.sortTypeArray.includes(sortListType)) {
      if (sortListType === 'trending') sortSelections[0].selectedClass = 'highlight-border'
      // if (sortListType === 'week') sortSelections[1].selectedClass = 'highlight-border'
      if (sortListType === 'all-time') sortSelections[1].selectedClass = 'highlight-border'
      if (sortListType === 'newest') sortSelections[2].selectedClass = 'highlight-border'
    }
    // This selects 'Newest' as the category to first highlight (since that's what we currently load first)
    else sortSelections[2].selectedClass = 'highlight-border';
    this.setState({ sortSelections: sortSelections });
  }
  // Based upon the user toggle, a query based upon the relevant parameters will initiate.
  toggleSort = (e, sortType, index) => {
    e.preventDefault()
    if (this.state.postsType === sortType) return
    globals.createRipple(e)
    const fetchTwenty = this.fetchTwenty;
    const sortSelections = this.state.sortSelections;
    sortSelections.forEach(sortType => {
      sortType.selectedClass = ''
    })
    localStorage.setItem('sortTypeSelection', sortType)
    sortSelections[index].selectedClass = 'highlight-border';
    this.setState({ sortSelections: sortSelections });
    // This prevents a database call if the user clicks on the same sortType they've already loaded.
    // This isn't being triggered fast enough sometimes. I think it's just capturing whatever page I'm on!
    // Sorting doesn't include home-posts because there is no sort for that.
    switch (sortType) {
      case globals.allTime:
        // postsType state switches display.
        // Here is where the check goes if there are already elements in the array. Like the other function. 
        // Then if there is, we take from that array. Otherwise, we call the API like normal. Easy here.
        // I do think I need to do a check if there are any items in the array. Is it a 0 array or a .length array.
        if (this.state.allTimePosts.length === 0) {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
            this.setState({ postsType: globals.allTime, allTimePostsIsLoaded: false })
            API.getAllTimePosts(this.state.categoryName, this.state.pageNumberAllTimePosts, this.cancelToken.token)
              .then(res => {
                this.props.opacityChange('removePostListOpacity')
                setTimeout(() => {
                  if (res.data.length === 0) {
                    this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: true, allTimeStartingLoaded: true })
                  }
                  // This is the check if there are some results, but no more.
                  else if (res.data.length < fetchTwenty) {
                    const updatedArray = this.firstLoadUpdateArray(res.data)
                    this.setState({ allTimePosts: updatedArray }, () => {
                      this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: true, allTimeStartingLoaded: true })
                    })
                  }
                  else {
                    const updatedArray = this.firstLoadUpdateArray(res.data)
                    this.setState({ allTimePosts: updatedArray }, () => {
                      this.setState({
                        noMoreResultsAllTimePosts: false,
                        allTimePostsIsLoaded: true,
                        allTimeStartingLoaded: true,
                      })
                    })
                  }
                  this.props.opacityChange('addPostListOpacity')
                }, 300)
              })
              .catch(error => {
                console.error(error);
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              });
          }, 300)

        }
        else {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
            this.setState({ postsType: globals.allTime })
          }, 300)
        }
        break
      case globals.trending:
        if (this.state.trendingPosts.length === 0) {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
            this.setState({ postsType: globals.trending, trendingStartingLoaded: false })
            API.getTrendingPostsByCategory(this.state.categoryName, this.state.pageNumberTrendingPosts, this.cancelToken.token)
              .then(res => {
                this.props.opacityChange('removePostListOpacity')
                setTimeout(() => {
                  if (res.data.length === 0) {
                    this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: true, trendingStartingLoaded: true })
                  }
                  // This is the check if there are some results, but no more.
                  else if (res.data.length < fetchTwenty) {
                    const updatedArray = this.firstLoadUpdateArray(res.data)
                    this.setState({ trendingPosts: updatedArray }, () => {
                      this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: true, trendingStartingLoaded: true })
                    })
                  }
                  else {
                    const updatedArray = this.firstLoadUpdateArray(res.data)
                    this.setState({ trendingPosts: updatedArray }, () => {
                      this.setState({
                        noMoreResultsTrendingPosts: false,
                        trendingPostsIsLoaded: true,
                        trendingStartingLoaded: true,
                      })
                    })
                  }
                  this.props.opacityChange('addPostListOpacity')
                }, 300)
              })
              .catch(error => {
                console.error(error);
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              });
          }, 300)

        }
        else {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
            this.setState({ postsType: globals.trending })
          }, 300)
        }
        break
      case globals.newest:
        if (this.state.newestPosts.length === 0) {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
            this.setState({ postsType: globals.newest, newestStartingLoaded: false })
            API.getNewestPosts(this.state.categoryName, this.state.pageNumberNewestPosts, this.cancelToken.token)
              .then(res => {
                this.props.opacityChange('removePostListOpacity')
                setTimeout(() => {
                  if (res.data.length === 0) {
                    this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: true, newestStartingLoaded: true, })
                  }
                  // This is the check if there are some results, but no more.
                  else if (res.data.length < fetchTwenty) {
                    const updatedArray = this.firstLoadUpdateArray(res.data)
                    this.setState({ newestPosts: updatedArray }, () => {
                      this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: true, newestStartingLoaded: true })
                    })
                  }
                  else {
                    const updatedArray = this.firstLoadUpdateArray(res.data)
                    this.setState({ newestPosts: updatedArray }, () => {
                      this.setState({
                        noMoreResultsNewestPosts: false,
                        newestPostsIsLoaded: true,
                        newestStartingLoaded: true
                      })
                    })
                  }
                  this.props.opacityChange('addPostListOpacity')
                }, 300)
              })
              .catch(error => {
                console.error(error);
                if (error.message === 'Operation canceled') return
                globals.toastError(toast, 'Unable to find content');
              });
          }, 300)

        }
        else {
          this.props.opacityChange('removePostListOpacity')
          setTimeout(() => {
            this.props.opacityChange('addPostListOpacity')
            this.setState({ postsType: globals.newest })
          }, 300)
        }
        break
      // case globals.week:
      //   if (this.state.weekPosts.length === 0) {
      //     this.props.opacityChange('removePostListOpacity')
      //     setTimeout(() => {
      //       this.props.opacityChange('addPostListOpacity')
      //       this.setState({ postsType: globals.week, weekStartingLoaded: false })
      //       API.getWeekPosts(this.state.categoryName, this.state.pageNumberWeekPosts, this.cancelToken.token)
      //         .then(res => {
      //           this.props.opacityChange('removePostListOpacity')
      //           setTimeout(() => {
      //             if (res.data.length === 0) {
      //               this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: true, weekStartingLoaded: true })
      //             }
      //             else if (res.data.length < fetchTwenty) {
      //               const updatedArray = this.firstLoadUpdateArray(res.data)
      //               this.setState({ weekPosts: updatedArray }, () => {
      //                 this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: true, weekStartingLoaded: true })
      //               })
      //             }
      //             else {
      //               const updatedArray = this.firstLoadUpdateArray(res.data)
      //               this.setState({ weekPosts: updatedArray }, () => {
      //                 this.setState({
      //                   noMoreResultsWeekPosts: false,
      //                   weekPostsIsLoaded: true,
      //                   weekStartingLoaded: true
      //                 })
      //               })
      //             }
      //             this.props.opacityChange('addPostListOpacity')
      //           }, 300)
      //         })
      //         .catch(error => {
      //           console.error(error);
      //           if (error.message === 'Operation canceled') return
      //           globals.toastError(toast, 'Unable to find content');
      //         });
      //     }, 300)

      //   }
      //   else {
      //     this.props.opacityChange('removePostListOpacity')
      //     setTimeout(() => {
      //       this.props.opacityChange('addPostListOpacity')
      //       this.setState({ postsType: globals.week })
      //     }, 300)
      //   }
      //   break
      default:
        return null
    }
  }
  postListPaginationLogic = (node) => {
    // Disconnects the current ref observer when it hits.
    if (this.observer.current) this.observer.current.disconnect()
    // Creates a new observer and in the grabMoreComments function, it updates the div where the refCallback lands on
    this.observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && !this.state.noMoreResults) {
        this.paginateByPostsType()
      }
    })
    // Observe the current node.
    if (node) this.observer.current.observe(node)
  }
  // This is my callback function for when the ref becomes .current (on the screen)
  refCallbackForPostsList = this.postListPaginationLogic.bind(this); // <- this is essentially `useCallback`

  updateExistingArray = (posts) => {
    let arrayType;
    switch (this.state.postsType) {
      case globals.homePosts:
        arrayType = this.state.homePosts
        break
      case globals.allTime:
        arrayType = this.state.allTimePosts
        break
      case globals.trending:
        arrayType = this.state.trendingPosts
        break
      case globals.newest:
        arrayType = this.state.newestPosts
        break
      // case globals.week:
      //   arrayType = this.state.weekPosts
      //   break
      case globals.followingPosts:
        arrayType = this.state.followingPosts
        break
      default: return
    }
    let addingToArray = arrayType
    // This adds the data on top of the existing savedHomePostsArray data.
    addingToArray.forEach(post => {
      post.ref = false
      post.lastItem = false
    })
    posts.map(post => {
      return addingToArray.push(post)
    })
    if (posts.length === this.fetchTwenty) {
      let itemFive = addingToArray[addingToArray.length - 15]
      itemFive.ref = true
      addingToArray.splice(addingToArray.length - 15, 1, itemFive)
    }

    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    // So this takes the index of the addingToArray, grabs one item, and replaces it with lastItem.
    addingToArray.splice(addingToArray.length - 1, 1, lastItem)
    return addingToArray;
  }
  // Paginating Function
  // So this function cannot be hit unless there was 20 posts on the page. The ref isn't added, otherwise.
  paginateByPostsType = () => {
    const fetchTwenty = this.fetchTwenty
    switch (this.state.postsType) {
      case globals.homePosts:
        // This is where I should set the pageNumber. This is the only location it changes.
        this.setState({ homePostsIsLoaded: false, pageNumberHomePosts: this.state.pageNumberHomePosts + 1 }, () => {
          API.getPostsForHomePage(this.state.pageNumberHomePosts, this.cancelToken.token)
            .then(res => {
              // If there are no results, we let the user know with the noMoreResults equalling true
              if (res.data.length === 0) {
                // Array is cleared, because otherwise whatever is leftover in it will be displayed
                // and make for a buggy experience
                // Could possibly clear the array of the properties at this point.
                let updatedPosts = this.state.homePosts
                updatedPosts.forEach(post => { post.ref = false })
                this.setState({ homePostsIsLoaded: true, noMoreResultsHomePosts: true, homePosts: updatedPosts })
              }
              else if (res.data.length < fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ homePosts: updatedArray }, () => {
                  this.setState({ homePostsIsLoaded: true, noMoreResultsHomePosts: true })
                })
              }
              // Case if there are 20 posts retrieved
              else {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ homePosts: updatedArray }, () => {
                  this.setState({ homePostsIsLoaded: true, noMoreResultsHomePosts: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case globals.followingPosts:
        // Guard clause if a user logs out and attempts to scroll down to generate more posts.
        if (!this.props.auth || !this.props.auth.id) return
        this.setState({ followingPostsIsLoaded: false, pageNumberFollowingPosts: this.state.pageNumberFollowingPosts + 1 }, () => {
          API.getFollowingPosts(this.state.pageNumberFollowingPosts, this.cancelToken.token)
            .then(res => {
              // If there are no results, we let the user know with the noMoreResults equalling true
              if (res.data.length === 0) {
                let updatedPosts = this.state.followingPosts
                updatedPosts.forEach(post => { post.ref = false })
                this.setState({ followingPostsIsLoaded: true, noMoreResultsFollowingPosts: true, followingPosts: updatedPosts })
              }
              else if (res.data.length < fetchTwenty) {
                // Case if the search has some results, but not all.
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ followingPosts: updatedArray }, () => {
                  this.setState({ followingPostsIsLoaded: true, noMoreResultsFollowingPosts: true })
                })
              }
              // Case if there are 20 posts retrieved
              else {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ followingPosts: updatedArray }, () => {
                  this.setState({ followingPostsIsLoaded: true, noMoreResultsFollowingPosts: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case globals.allTime:
        this.setState({ allTimePostsIsLoaded: false, pageNumberAllTimePosts: this.state.pageNumberAllTimePosts + 1 }, () => {
          API.getAllTimePosts(this.state.categoryName, this.state.pageNumberAllTimePosts, this.cancelToken.token)
            .then(res => {
              if (res.data.length === 0) {
                let updatedPosts = this.state.allTimePosts
                updatedPosts.forEach(post => { post.ref = false })
                this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: true, allTimePosts: updatedPosts })
              }
              else if (res.data.length < fetchTwenty) {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ allTimePosts: updatedArray }, () => {
                  this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: true })
                })
              }
              else {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ allTimePosts: updatedArray }, () => {
                  this.setState({ allTimePostsIsLoaded: true, noMoreResultsAllTimePosts: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case globals.newest:
        this.setState({ newestPostsIsLoaded: false, pageNumberNewestPosts: this.state.pageNumberNewestPosts + 1 }, () => {
          API.getNewestPosts(this.state.categoryName, this.state.pageNumberNewestPosts, this.cancelToken.token)
            .then(res => {
              if (res.data.length === 0) {
                let updatedPosts = this.state.newestPosts
                updatedPosts.forEach(post => { post.ref = false })
                this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: true, newestPosts: updatedPosts })
              }
              else if (res.data.length < fetchTwenty) {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ newestPosts: updatedArray }, () => {
                  this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: true })
                })
              }
              else {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ newestPosts: updatedArray }, () => {
                  this.setState({ newestPostsIsLoaded: true, noMoreResultsNewestPosts: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      case globals.trending:
        this.setState({ trendingPostsIsLoaded: false, pageNumberTrendingPosts: this.state.pageNumberTrendingPosts + 1 }, () => {
          API.getTrendingPostsByCategory(this.state.categoryName, this.state.pageNumberTrendingPosts, this.cancelToken.token)
            .then(res => {
              if (res.data.length === 0) {
                let updatedPosts = this.state.trendingPosts
                updatedPosts.forEach(post => { post.ref = false })
                this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: true, trendingPosts: updatedPosts })
              }
              else if (res.data.length < fetchTwenty) {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ trendingPosts: updatedArray }, () => {
                  this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: true })
                })
              }
              else {
                const updatedArray = this.updateExistingArray(res.data)
                this.setState({ trendingPosts: updatedArray }, () => {
                  this.setState({ trendingPostsIsLoaded: true, noMoreResultsTrendingPosts: false })
                })
              }
            })
            .catch(error => {
              console.error(error);
              if (error.message === 'Operation canceled') return
              globals.toastError(toast, 'Unable to find content');
            });
        })
        break
      // case globals.week:
      //   this.setState({ weekPostsIsLoaded: false, pageNumberWeekPosts: this.state.pageNumberWeekPosts + 1 }, () => {
      //     API.getWeekPosts(this.state.categoryName, this.state.pageNumberWeekPosts, this.cancelToken.token)
      //       .then(res => {
      //         if (res.data.length === 0) {
      //           let updatedPosts = this.state.weekPosts
      //           updatedPosts.forEach(post => { post.ref = false })
      //           this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: true, weekPosts: updatedPosts })
      //         }
      //         else if (res.data.length < fetchTwenty) {
      //           const updatedArray = this.updateExistingArray(res.data)
      //           this.setState({ weekPosts: updatedArray }, () => {
      //             this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: true })
      //           })
      //         }
      //         else {
      //           const updatedArray = this.updateExistingArray(res.data)
      //           this.setState({ weekPosts: updatedArray }, () => {
      //             this.setState({ weekPostsIsLoaded: true, noMoreResultsWeekPosts: false })
      //           })
      //         }
      //       })
      //       .catch(error => {
      //         console.error(error);
      //         if (error.message === 'Operation canceled') return
      //         globals.toastError(toast, 'Unable to find content');
      //       });
      //   })
      //   break
      default:
        return null;
    }
  };
  clickToCreatePost = (e, categoryName) => {
    e.preventDefault();
    globals.createRipple(e)
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostListOpacity')
    setTimeout(() => {
      this.props.history.push(`/categories/${categoryName}/posts/new`)
    }, 300)
  };
  clickToPostDetailPage = (slug) => {
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostListOpacity')
    setTimeout(() => {
      this.props.history.push(`/posts/${slug}`)
    }, 300)
  };
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    // Do an opacityChange
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostListOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  /////////////////////////
  //LOGIN MODAL FUNCTIONS//
  /////////////////////////
  toggleLoginModal = () => {
    this.setState((prevState) => ({ showLoginModal: !prevState.showLoginModal }));
  };
  closeLoginModal = () => this.setState({ showLoginModal: false })
  toggleCategoryModal = () => {
    if (this.state.showCategoryModal) {
      this.setState({ showCategoryModal: false })
    }
    if (!this.state.showCategoryModal) {
      this.setState({ showCategoryModal: true, isCategorySelected: false, categorySelected: '' })
    }
  }
  authenticateWithHandCash = (e) => {
    e.preventDefault();
    API.authenticateWithHandCash(this.cancelToken.token)
      .then(res => {
        const pathNameString = window.location.pathname
        const redirectionLoginUrl = res.data.redirectionLoginUrl
        const redirectWithPath = `${redirectionLoginUrl}&redirectLink=${pathNameString}`
        window.location.assign(redirectWithPath)
      })
      .catch(error => {
        console.error(error.response.data.message);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, error.response.data.message);
      });
  }
  submitCategoryForCreatePost = (e) => {
    e.preventDefault()
    if (!this.state.isCategorySelected) return globals.toastError(toast, 'Please select a category');
    if (!globals.categoryHrefs.includes(this.state.categorySelected)) return globals.toastError(toast, 'Invalid category');
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostListOpacity')
    this.toggleCategoryModal()
    setTimeout(() => {
      this.props.history.push(`/categories/${this.state.categorySelected}/posts/new`)
    }, 300)
  }
  // Based upon the Radio Button selected, this.state.pollOptionSelected is changed
  handleRadioCategorySelection = (event) => {
    const value = event.target.value;
    if (value === this.state.categorySelected) return
    this.setState({ categorySelected: value, isCategorySelected: true });
  }
  render() {
    return (
      <>
        <MainPage
          {...this.state}
          toggleSort={this.toggleSort}
          refCallbackForPostsList={this.refCallbackForPostsList}
          categoryOpacity={this.props.categoryOpacity}
          postsOpacity={this.props.postsOpacity}
          clickToPostDetailPage={this.clickToPostDetailPage}
          clickToCreatePost={this.clickToCreatePost}
          setView={this.setView}
          goToUserProfilePage={this.goToUserProfilePage}
          auth={this.props.auth}
          toggleLoginModal={this.toggleLoginModal}
          userDatabaseData={this.props.userDatabaseData}
          toggleCategoryModal={this.toggleCategoryModal}
        />
        <LoginModal
          showLoginModal={this.state.showLoginModal}
          closeLoginModal={this.closeLoginModal}
          authenticateWithHandCash={this.authenticateWithHandCash}
        />
        {this.props.auth?.id && this.props.userDatabaseData
          ?
          <CategoryModal
            showCategoryModal={this.state.showCategoryModal}
            toggleCategoryModal={this.toggleCategoryModal}
            authenticateWithHandCash={this.authenticateWithHandCash}
            submitCategoryForCreatePost={this.submitCategoryForCreatePost}
            isCategorySelected={this.state.isCategorySelected}
            handleRadioCategorySelection={this.handleRadioCategorySelection}
            userDatabaseData={this.props.userDatabaseData}
            categorySelected={this.state.categorySelected}
          />
          : <></>
        }
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    categoryOpacity: state.opacity.categoryOpacity,
    postsOpacity: state.opacity.postsOpacity,
    auth: state.auth.handCashLogInData,
    userDatabaseData: state.auth.userDatabaseData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPageContainer);
