import React from 'react'
import Modal from "react-modal";
import { 
  Icon,
  closeCircleO, 
} from '../../config/icons';

const ConfirmCancelModal = props => {
  const {
    showConfirmCancelModal,
    closeConfirmCancelModal,
    confirmCancel,
  } = props;
  const styles = {
    base: `modal-content first-text gradient-background-four shadow-effect-five
    mt-32 w-full px-1 sm:w-3/4 md:w-1/2 lg:w-1/2 xl:w-1/3 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showConfirmCancelModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="modal-overlay mr-4 sm:mr-0"
        onRequestClose={closeConfirmCancelModal}
        closeTimeoutMS={300}
        contentLabel={'HandCash Login Modal'}
      >
        <div className={`text-center`}>
          <button onClick={closeConfirmCancelModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
          <div className={`teko-font text-3xl sm:text-3xl mb-2 logo-style ml-6`}>
            <div>Cancel?</div>
          </div>
          <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
          {/* Conditional render if tip message should exist or not */}
          {/* Confirmation */}
          <div className={`flex justify-between mt-5 mb-1`}>
            <button
              className={`text-sm py-1 px-3 shadow-effect-seven font-header gradient-background-green rounded-xl border-2 font-header text-white border-brand-green ml-2`}
              onClick={e => confirmCancel(e)}
            >
              Yes
            </button>
            <button
              className={`text-white gradient-background-red shadow-effect-seven
              text-sm rounded-xl border-2 font-header border-red-700 py-1 px-3 mr-2`}
              onClick={closeConfirmCancelModal}
            >
              <span className={`px-px mx-2px`}>No</span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmCancelModal;
