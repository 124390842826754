import React, { Component } from 'react';
import { globals } from '../../config/globals';
import Moment from 'react-moment';

import SkeletonLoaderSingleLine from '../Widgets/SkeletonLoaderSingleLine';
import SkeletonLoaderForPosts from '../Widgets/SkeletonLoaderForPosts';
import { 
  Icon,
  starSolid,
  cameraLock,
  commentsIcon,
  eyeSolid,
  bxKey,
  shareSquare,
  clockIcon,
  vote20Regular,
  calendarClock,
  thumbsUp
} from '../../config/icons';


class PostListItem extends Component {
  state = {
    viewMore: false,
    stampshotHours: ''
  }
  componentDidMount() {
    if (this.props.post.description) this.checkForOverflow()
    // Stampshot check
    if (this.props.post.stampshotTime && !this.props.post.isFrozen
      && this.props.post.stampshots.length === 0) {
      this.getStampshotHours()
    }
  }
  componentDidUpdate() {
    if (this.props.listOrPostView === 'listView' || this.state.viewMore === null || !this.props.post.description) return
    this.updateCheckForOverflow()
  }
  toggleViewMoreOrLess = (e) => {
    const expandableElement = e.target.closest('[data-expandable]')
    if (!this.state.viewMore) {
      expandableElement.classList.toggle('expanded')
      this.setState({ viewMore: true })
    }
    else {
      expandableElement.classList.remove('expanded')
      this.setState({ viewMore: false })
    }
  }
  checkForOverflow() {
    const expandableElement = document.getElementById(`post-list-description-${this.props.index}`)
    if (!expandableElement || expandableElement === null) return
    if (expandableElement.classList.contains('expanded')) return
    const expandableText = expandableElement.querySelector('[data-expand-text]')
    const overflowing = expandableText.scrollHeight > expandableText.clientHeight
    expandableElement.dataset.overflow = overflowing
    if (overflowing === false) {
      this.setState({ viewMore: null })
    }
  }
  updateCheckForOverflow() {
    const expandableElement = document.getElementById(`post-list-description-${this.props.index}`)
    if (!expandableElement || expandableElement === null) return
    if (expandableElement.classList.contains('expanded')) return
    const expandableText = expandableElement.querySelector('[data-expand-text]')
    const overflowing = expandableText.scrollHeight > expandableText.clientHeight
    expandableElement.dataset.overflow = overflowing
    if (overflowing === true && this.state.viewMore === true) {
      expandableElement.classList.remove('expanded')
      this.setState({ viewMore: false })
    }
  }
  getStampshotHours = () => {
    const countDownDate = new Date(this.props.post.stampshotTime)
    // Get today's date and time
    const now = new Date()
    // Calculate the clock
    let seconds = Math.floor((countDownDate - (now)) / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    hours = hours - (days * 24);
    // This adds a zero if there is less than 9
    minutes = minutes - (days * 24 * 60) - (hours * 60);
    minutes = minutes <= 9 ? '0' + minutes : minutes;
    seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
    seconds = seconds <= 9 ? '0' + seconds : seconds;
    // Have to convert back to hours just to get the display correct via this logic
    hours = hours + (days * 24)
    hours = hours <= 9 ? '0' + hours : hours;
    let display = `${hours} hours`
    if (hours < 1) display = `<1 hour`
    this.setState({ stampshotHours: `${display}` })
  }
  render() {
    const {
      post,
      refCallbackForPostsList,
      noMoreResults,
      postsAreLoaded,
      clickToPostDetailPage,
      listOrPostView,
      index,
      goToUserProfilePage,
      auth,
      categoryName
    } = this.props;
    return (
      <>
        {listOrPostView === 'postView' ?
          <>
            {/* Wrapper div */}
            <div className={`first-text gradient-background-description-two shadow-effect-eight word-wrap rounded-xl transition w-full px-2 mb-2 py-1`}>
              <div className={`flex justify-between w-full items-center pt-2px pb-1`}>
                {/* Top left  */}
                <div className={`flex flex-wrap text-sm`}>
                  <span className={`${post.actionTakerIsMember && auth?.handle !== post.postCreatorHandle ? `member-tooltip ` : ``}`} data-member='standard' data-tooltip='Member'>
                    <div className={`p-2 -m-2 ${post.actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='22' height='22'
                        alt="Avatar"
                        src={post.postCreatorAvatarURL}
                        className={`rounded-xl ${post.actionTakerIsMember ? `shadow-effect-six` : ``}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                      >
                      </img>
                    </div>
                  </span>
                  <div className={`mr-6px`}>
                    <button onClick={(e) => goToUserProfilePage(e, post.postCreatorHandle)}
                      className={`fifth-hover ml-1 hover:underline text-underline-under`}>
                      {post.postCreatorHandle}
                    </button>
                  </div>
                  {post.sharerHandle
                    ?
                    <div className={`text-medium-gray mr-1`}>
                      Shared by <button onClick={(e) => goToUserProfilePage(e, post.sharerHandle)}
                        className={`fifth-hover hover:underline`}>{post.sharerHandle}</button>
                    </div>
                    : <></>
                  }
                </div>
                {/* Top right */}
                <div className={`text-medium-gray text-xs text-right`}>
                  <Moment fromNow>
                    {post.createdAt}
                  </Moment>
                </div>
              </div>
              {/* hr for border */}
              <hr className={`sixth-border outsetBorder border mb-2 mt-px opacity-50`}></hr>
              {/* 2nd row title & Plus sign at the end*/}
              <div className={`mt-2 mb-2 text-lg sm:text-xl text-left overflow-hidden `}>
                <button
                  className={`word-wrap fifth-hover text-left thirteenth-text rounded hover:underline text-underline-under`}
                  onClick={() => clickToPostDetailPage(post.slug)}
                >
                  {post.title}
                </button>
              </div>
              {/* Middle Description (if applicable) */}
              <div className={``}>
                {/* If there is a post.description.. */}
                {post.description
                  ?
                  <>
                    <div id={`post-list-description-${index}`} data-expandable className={`text-sm`}>
                      <div className={``} data-expand-text>{post.description}</div>
                      {this.state.viewMore === null
                        ? <></>
                        :
                        <button onClick={this.toggleViewMoreOrLess} data-expand-button
                          className={`text-medium-gray mt-1 fifth-hover`}>
                          {this.state.viewMore ? `[ View Less ]` : `[ View More ]`}
                        </button>
                      }
                    </div>
                  </>
                  : <></>
                }
                <hr className={`sixth-border outsetBorder border mt-2 opacity-50`}></hr>
              </div>
              {/* Bottom Wrapper */}
              <div className={`flex flex-wrap justify-between w-full text-xs text-medium-gray items-center pt-1 pb-2px`}>
                {/* Bottom left */}
                <div className={`flex flex-wrap items-center`}>
                  {/* Amount earned */}
                  <div className={`flex`}>
                    <div className={`fifth-text sm:hidden font-bold pointer-events-none mr-6px`}>
                      ${post.totalValueReceived}
                    </div>
                    <div className={`hidden mr-6px  sm:inline-block tooltip`} data-tooltip="Earned">
                      <span className={`pointer-events-none font-bold fifth-text`}>${post.totalValueReceived}</span>
                    </div>
                  </div>
                  {/* Thumbs */}
                  <div className={`flex`} >
                    <div className={`sm:hidden inline-block mr-1 mt-3px`}>
                      <Icon icon={thumbsUp} height={11} />
                    </div>
                    <div className={`tooltip hidden sm:inline-block mr-1 mt-3px`} data-tooltip="Thumbs">
                      <Icon icon={thumbsUp} height={11} />
                    </div>
                    <div className={`mr-1 pointer-events-none`}>{post.numThumbs}</div>
                  </div>
                  {/* Views */}
                  <div className={`flex`}>
                    <div className={`sm:hidden inline-block mr-2px`}>
                      <Icon icon={eyeSolid} height={17} width={16} />
                    </div>
                    <div className={`hidden sm:inline-block tooltip mr-2px`} data-tooltip="Views">
                      <Icon icon={eyeSolid} height={17} width={16} />
                    </div>
                    <div className={`mr-6px pointer-events-none`}>{post.numViews}</div>
                  </div>
                  {/* Comments */}
                  <div className={`flex`}>
                    <div className={`sm:hidden inline-block mr-1 mt-2px`}>
                      <Icon icon={commentsIcon} height={13} />
                    </div>
                    <div className={`tooltip hidden sm:inline-block mr-1 mt-2px`} data-tooltip="Comments">
                      <Icon icon={commentsIcon} height={13} />
                    </div>
                    <div className={`mr-6px pointer-events-none`}>{post.numComments}</div>
                  </div>
                  {/* Paywall */}
                  {post.postHasPaywall
                    ?
                    <>
                      <div className={`flex`} >
                        <div className={`sm:hidden inline-block -ml-2px`}>
                          <Icon icon={bxKey} height={17} width={16} />
                        </div>
                        <div className={`tooltip hidden sm:inline-block mr-px`} data-tooltip="Unlocks">
                          <Icon icon={bxKey} height={17} width={16} />
                        </div>
                        <div className={`mr-1 pointer-events-none`}>{post.numPaywallPurchases}</div>
                      </div>
                      {post.numberOfPaywallRatings > 0
                        ?
                        <>
                          {/* Large Screen */}
                          <div className='flex'>
                            <div className={`mr-6px hidden sm:flex items-center`}>
                              <div className='tooltip flex items-center' data-tooltip="Rating">
                                <div className={`text-yellow-500 mx-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                              </div>
                              <div className={`pointer-events-none`}>
                                {parseFloat(parseInt(post.paywallRatingTotal) / parseInt(post.numberOfPaywallRatings)).toFixed(2)}
                              </div>
                            </div>
                            {/* Small Screen */}
                            <div className={`mr-6px flex items-center sm:hidden`}>
                              <div className={`text-yellow-500 mx-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                              <div className={`pointer-events-none`} >
                                {parseFloat(parseInt(post.paywallRatingTotal) / parseInt(post.numberOfPaywallRatings)).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </>
                        : <></>
                      }
                    </>
                    : <></>
                  }
                  {/* Shares */}
                  {categoryName === 'water-cooler'
                    ? <></>
                    :
                    <div className={`flex`}>
                      <div className={`sm:hidden inline-block mr-px mt-2px`}>
                        <Icon icon={shareSquare} height={13} width={13} />
                      </div>
                      <div className={`tooltip hidden sm:inline-block mr-px mt-2px`} data-tooltip="Shares">
                        <Icon icon={shareSquare} height={13} width={13} />
                      </div>
                      <div className={`ml-px pointer-events-none mr-6px`}>{post.numShares}</div>
                    </div>
                  }
                  {/* Poll Votes */}
                  {post.category === 'polls'
                    ?
                    <div className={`flex items-center`}>
                      <div className={`sm:hidden inline-block mr-px -mt-px`}>
                        <Icon icon={vote20Regular} height={13} width={13} />
                      </div>
                      <div className={`tooltip hidden sm:inline-block mr-px -mt-px`} data-tooltip="Votes">
                        <Icon icon={vote20Regular} height={13} width={13} />
                      </div>
                      <div className={`ml-px pointer-events-none mr-2`}>{post.numPollVotes}</div>
                    </div>
                    : <></>
                  }
                  {/* readTime */}
                  {!['polls', 'lists', 'questions'].includes(post.category) && post.readTime?.length > 0
                    ? <div className='flex items-center'>
                      <div className={`mr-1`}>
                        <Icon icon={clockIcon} height={12} width={12} />
                      </div>
                      <div className={`mr-2`}>
                        {post.readTime}
                      </div>
                    </div>
                    : <></>
                  }
                  {['polls', 'lists', 'questions'].includes(post.category) && post.stampshotTime
                    ? !post.isFrozen && this.state.stampshotHours.length > 0
                      ?
                      <div className={`flex items-center`}>
                        <div className={`sm:hidden inline-block mr-1`}>
                          <Icon icon={calendarClock} height={13} width={13} />
                        </div>
                        <div className={`tooltip hidden sm:inline-block mr-1`} data-tooltip="Time until Stampshot">
                          <Icon icon={calendarClock} height={13} width={13} />
                        </div>
                        <div className={`mr-6px`}>
                          {this.state.stampshotHours}
                        </div>
                      </div>
                      // If stampshot time has expired
                      :
                      <div className={`flex items-center`}>
                        <div className={`sm:hidden inline-block mr-1`}>
                          <Icon icon={cameraLock} height={13} width={13} />
                        </div>
                        <div className={`tooltip hidden sm:inline-block mr-1`} data-tooltip="Stampshot Frozen">
                          <Icon icon={cameraLock} height={13} width={13} />
                        </div>
                        <div className={`mr-6px`}>
                          Frozen
                        </div>
                      </div>
                    // If it is not a poll or list, or doesn't have a stampshot
                    : <></>
                  }
                </div>
                {/* Bottom right #TAGS */}
                <div className={`flex text-medium-gray overflow-hidden flex-wrap`}>
                  {categoryName === 'all'
                    ? <> <div className={`mr-1`}>#{post.categoryDisplayName}</div></>
                    : post.tags && post.tags.length > 0
                      ?
                      <>
                        {post.tags.map((tag, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className={`mr-1`}>#{tag}</div>
                            </React.Fragment>
                          )
                        })}
                      </>
                      : <></>
                  }
                </div>
              </div>
            </div>
          </>
          // List View
          :
          <>
            <div className={`first-text gradient-background-four flex justify-between shadow-effect-eight
              word-wrap rounded-xl transition w-full px-2 mb-1 py-1 text-xs sm:text-sm items-center`}>
              <div className={`flex mr-1`}>
                {/* Total Earned */}
                <div className={`flex fifth-text mr-6px min-w-9 sm:min-w-11`}>
                  <b>${post.totalValueReceived}</b>
                </div>
                {/* Title & Creator*/}
                <div className={`word-wrap flex flex-wrap`}>
                  <button className={`fifth-hover thirteenth-text mr-1 hover:underline text-underline-under text-left`} onClick={() => clickToPostDetailPage(post.slug)}>
                    {post.title}
                  </button>
                  <div className={`text-medium-gray mr-1`}>by <button className={`text-medium-gray hover:underline text-left fifth-hover`}
                    onClick={(e) => goToUserProfilePage(e, post.postCreatorHandle)}>
                    {post.postCreatorHandle}
                  </button>
                  </div>
                </div>
              </div>
              <div className={`text-medium-gray hidden sm:block text-xs text-right`}>
                <Moment fromNow>
                  {post.createdAt}
                </Moment>
              </div>
            </div>
          </>
        }
        {/* End Wrapper Div */}
        {post.ref
          ? <div ref={refCallbackForPostsList}></div>
          : <></>
        }
        {post.lastItem
          ? noMoreResults
            ?
            <div className={`rounded-xl text-sm text-center my-6 p-1 text-medium-gray-lighter gradient-background-two shadow-effect-one`}>
              {globals.noContentToDisplay}
            </div>
            : postsAreLoaded
              ? <></>
              : listOrPostView === 'postView'
                ?
                <>
                  <SkeletonLoaderForPosts />
                  <SkeletonLoaderForPosts />
                  <SkeletonLoaderForPosts />
                  <SkeletonLoaderForPosts />
                  <SkeletonLoaderForPosts />
                </>
                :
                <>
                  <SkeletonLoaderSingleLine />
                  <SkeletonLoaderSingleLine />
                  <SkeletonLoaderSingleLine />
                  <SkeletonLoaderSingleLine />
                  <SkeletonLoaderSingleLine />
                </>
          : <></>
        }
      </>
    )
  }
}

export default PostListItem;