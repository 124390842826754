import React from 'react'
import Modal from "react-modal";
import QuillEditorForLists from '../Editors/QuillEditorForLists';
import { 
  Icon,
  closeCircleO, 
} from '../../config/icons';

const ProfileInputModal = props => {
  const {
    showModal,
    toggleInputModal,
    selectedInputType,
    selectedInputData,
    selectedTitle,
    selectedPreEdited,
    handleChange,
    handleProfileInputSubmit,
    selectedSingleLineInput,
    onSingleInputChange,
    selectedPlaceholder,
    // Twetch userName
    onTwetchUserNameChange,
    selectedTwetchUserName,
    onKeyDown,
  } = props;
  const styles = {
    base: `${selectedSingleLineInput ? 'mt-32' : 'mt-10'} array-modal-content first-text gradient-background-four shadow-effect-five
    w-full px-1 sm:w-3/4 md:w-2/3 xl:w-1/2 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showModal}
        shouldReturnFocusAfterClose={true}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={true}
        className={styles}
        onRequestClose={(e => toggleInputModal(e, selectedInputType))}
        overlayClassName="array-modal-overlay"
        closeTimeoutMS={300}
        contentLabel={'Profile Editing Modal'}
      >
        <div className={`text-center pb-1`}>
          <form>
            <button onClick={(e => toggleInputModal(e, selectedInputType))} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
            <div className={`teko-font text-3xl -mt-1 mb-1 logo-style ml-6`}>
              {selectedTitle}
            </div>
            {selectedInputType === 'linkedInLink' || selectedInputType === 'youTubeChannel'
              ?
              <>
                <div className={`eleventh-text text-sm -mt-2`}>
                  {selectedPlaceholder}
                </div>
              </>
              : <></>
            }
            <div className={`mx-2 mb-5`}>
              {selectedSingleLineInput
                ? <>
                  <div className={`rounded-lg text-gray-900 mt-2 shadow-effect-five`}>
                    <div className={`transition first-text 
                      ${selectedInputType === 'twetchNumber' ? `custom-input-container` : ''}`}
                      data-name="Twetch Number"
                    >
                      <div className={`rounded-md shadow-effect-six`}>
                        <input type='text'
                          className={`gradient-background-seven shadow-effect-ten border 
                          outline-none w-full rounded-md px-2 h-50px text-gray-900 transition`
                          }
                          aria-label={`Edit ${selectedTitle} Input`}
                          id={`${selectedTitle}`} value={selectedInputData}
                          placeholder={`${selectedPlaceholder}`}
                          onChange={(e) => onSingleInputChange(e)}
                          onKeyDown={(e) => onKeyDown(e, selectedInputType, selectedInputData, selectedPreEdited)}
                        >
                        </input>
                      </div>
                    </div>
                  </div>
                  {selectedInputType === 'twetchNumber'
                    ?
                    <>
                      <div className={`rounded-lg text-gray-900 mt-6 shadow-effect-five`}>
                        <div className={`transition first-text custom-input-container`} data-name="Twetch Username">
                          <div className={`rounded-md shadow-effect-six`}>
                            <input type='text'
                              className={`gradient-background-seven shadow-effect-ten border 
                              outline-none w-full rounded-md px-2 h-50px text-gray-900 transition`
                              }
                              aria-label={`Edit Twetch Username Input`}
                              id={`twetch-username`} value={selectedTwetchUserName}
                              placeholder={`Enter your Twetch Username here`}
                              onChange={(e) => onTwetchUserNameChange(e)}
                              onKeyDown={(e) => onKeyDown(e, selectedInputType, selectedInputData, selectedPreEdited)}
                            >
                            </input>
                          </div>
                        </div>
                      </div>
                    </>
                    : <></>
                  }
                </>
                : <>
                  <div className={`rounded-lg text-gray-900 mt-3px text-left shadow-effect-five`}>
                    <QuillEditorForLists handleEditorChange={handleChange} content={selectedInputData} selectedPlaceholder={selectedPlaceholder}/>
                  </div>
                </>
              }
            </div>
            <div className={`flex justify-between`}>
              <button className={`ml-2 py-1 px-2 gradient-background-green text-sm rounded-xl border-2 font-header
                text-white border-brand-green transition-to-opacity`}
                onClick={(e) => handleProfileInputSubmit(e, selectedInputType, selectedInputData, selectedPreEdited)}
              >
                Submit
              </button>
              <button className={`py-1 px-2 cursor-pointer self-center mr-2 gradient-background-red 
                text-white text-sm rounded-xl border-2 font-header border-red-700`}
                onClick={(e) => toggleInputModal(e, selectedInputType)}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  )
}

export default ProfileInputModal;
