import React from 'react';
import Moment from 'react-moment';
import parse from 'html-react-parser';
import AddComment from './AddComment';
import CommentThumbsComponent from './CommentThumbsComponent';
import TierThreeCommentsContainer from './TierThreeCommentsContainer';
import SkeletonLoaderForPosts from '../Widgets/SkeletonLoaderForPosts';
import { 
  Icon,
  commentsIcon, 
  createOutline, 
  thumbsUp, 
  caretDown20Filled,
} from '../../config/icons';
const TierTwoComments = (props) => {
  const {
    comment,
    tierThreeComment,
    editorVisible,
    index,
    handleTierThreeCommentChange,
    toggleEditor,
    addExpandedClass,
    tierTwoCommentOpacity,
    tierThreeOpacity,
    levelThreeCommentOpacity,
    togglePaymentModal,
    auth,
    userDatabaseData,
    goToUserProfilePage,
    tierOneCommentId,
    tierOneCommentIndex,
    // Editing Comments
    showCommentEditor,
    handleEditCommentChange,
    editedCommentBody,
    toggleCommentEditor,
    editedCommentBodyError,
    editedOverallError,
    updateCommentOpacity,
    // Tier 3
    tierThreeComments,
    tierThreeVisible,
    displayTierThreeComments,
    tierThreeIsLoaded,
    handleTierThreeCommentSubmit,
    addAndFindT3Comment,
    addThumbsToTierThreeComment,
    postCreatorHandle,
    storeUserDatabaseData,
    history,
    updateT3CommentsArray,
    post,
    setTierThreeComments,
    updateTier1CommentsAndPostNumbersAfterT3Creation,
    updateTier2CommentsData,
    toggleCommentThumbsArrayModal,
    toggleConfirmCancelModal,
    toggleTipModalForT2Thumbs,
  } = props
  return (
    <>
      {/* Tier Two Comment data presentation*/}
      <div id={`${comment._id}`} className={`rounded-xl my-2 ${showCommentEditor ? `p-0` : `pt-2 px-2`} transition-to-opacity ${tierTwoCommentOpacity} first-text post-detail-background shadow-effect-one`}>
        <div className={`${updateCommentOpacity} transition-to-opacity`}>
          {!showCommentEditor
            ? <>
              {/* Top left  */}
              <div className={`text-sm inline-flex justify-between w-full `}>
                <div className={`inline-flex items-center`}>
                  <span className={`${comment.actionTakerIsMember && auth?.handle !== comment.commentCreatorHandle ? `member-tooltip ` : ``}`} data-member='standard' data-tooltip='Member'>
                    <div className={`p-2 -m-2 ${comment.actionTakerIsMember ? `flip-hover` : ``}`}>
                      <img width='22' height='22'
                        alt="Avatar"
                        src={comment.commentCreatorAvatarURL}
                        className={`rounded-xl ${comment.actionTakerIsMember ? `shadow-effect-six` : ``}`}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = '/images/round-handcash-logo.png'
                        }}
                      >
                      </img>
                    </div>
                  </span>
                  <div className={`-mt-px ml-1 text-sm`}>
                    <button className={`fifth-hover hover:underline text-sm`}
                      onClick={(e) => goToUserProfilePage(e, comment.commentCreatorHandle)}>
                      {comment.commentCreatorHandle}</button> earned <span className={`fifth-text font-bold`}>
                      ${comment.totalValueReceived}
                    </span>
                  </div>
                </div>
                {/* Top right */}
                <div className={`text-medium-gray inline-flex items-center`}>
                  <div className={`mx-1 -mt-px`}>
                    <Moment fromNow>
                      {new Date(comment.createdAt)}
                    </Moment>
                  </div>
                  {/* Edit */}
                  {auth && comment.commentCreatorHandCashId === auth.id
                    ?
                    <>
                      <button onClick={(e) => toggleCommentEditor(e)}
                        className={`sixth-hover align-middle h-5 -mt-2px`}>
                        <span className={`mr-1`}>
                          <Icon icon={createOutline} height={18} width={18} />
                        </span>
                      </button>
                    </>
                    : <></>
                  }
                </div>
              </div>
                                {/* Dividers & body */}
              <hr className={`sixth-border outsetBorder border opacity-50`}></hr>
              <div className={`first-text text-base my-2 clearfix parsed-editor-data`}>{parse(comment.body)}</div>
              <hr className={`sixth-border outsetBorder border opacity-50`}></hr>
              {/* Bottom */}
              <div className={`flex flex-wrap items-center justify-between text-sm mt-5px`}>
                {/* Bottom Left */}
                <div className={`flex flex-wrap pb-2`}>
                  <div className={`mr-1`}>
                    <CommentThumbsComponent
                      toggleCommentModal={toggleTipModalForT2Thumbs}
                      auth={auth}
                      comment={comment}
                      userDatabaseData={userDatabaseData}
                      index={index}
                      numThumbs={comment.numThumbs}
                    />
                  </div>
                  {/* Reply */}
                  {auth && auth.id
                    ?
                    <>
                      <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                      <div className={`fifth-hover flex`}>
                        <div className={`mt-3px mr-px`}>
                          <Icon icon={commentsIcon} height={15} width={15} />
                        </div>
                        <button onClick={(e) => toggleEditor(e)} className={`ml-px mr-1`}>
                          {!editorVisible
                            ? <span className={`fifth-hover`}>Reply</span>
                            : <span className={`fifth-hover`}>Close</span>
                          }
                        </button>
                      </div>
                      {comment.hasIframeInComment
                        ? <></>
                        :
                        <>
                          <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                          <button className={`fifth-hover mr-1`}
                            onClick={(e) => toggleEditor(e, comment.body, comment.commentCreatorHandle, comment.createdAt)} >
                            Quote
                          </button>
                        </>
                      }
                    </>
                    : <> </>
                  }
                  {/* Conditional render based upon the numChildComments, 
              then whether tierTwoVisible is true/false */}
                  {comment.numChildComments === 0
                    ?
                    <></>
                    : tierThreeVisible
                      ?
                      <>
                        <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                        <button className={`fifth-hover mr-1`}
                          onClick={() => displayTierThreeComments(comment._id)}>Hide ({`${comment.numChildComments}`})
                        </button>
                      </>
                      :
                      <>
                        <div className={`mr-1 -mt-px text-medium-gray`}>|</div>
                        <button className={`description-color font-bold fifth-hover mr-1 flex items-center`}
                          onClick={() => displayTierThreeComments(comment._id)}>View More ({`${comment.numChildComments}`})
                          <div className="ml-px mt-1 pointer-events-none"><Icon icon={caretDown20Filled} height={17} width={17} /></div>
                        </button>
                      </>
                  }
                </div>
                {/* Bottom Right */}
                <div className={`pb-6px`}>
                  {comment.numThumbs > 0
                    ? <div className={``}>
                      <button onClick={(e) => toggleCommentThumbsArrayModal(e, "COMMENT_THUMBS_ARRAY")}
                        className={`font-header shadow-effect-seven
                        eighth-text gradient-background-twelve
                          items-center flex  button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                        <div className={`-mt-px`}>
                          <Icon icon={thumbsUp} height={12} width={12} />
                        </div><span className={`ml-px`}>Thumbers</span>
                      </button>
                    </div>
                    : <></>
                  }
                </div>
              </div>
            </>
            // If showCommentEditor is true
            :
            <>
              <div className={`rounded-xl transition p-1 sm:px-4 sixth-text`}>
                <AddComment
                  handleCommentChange={handleEditCommentChange}
                  togglePaymentModal={togglePaymentModal}
                  commentBody={editedCommentBody}
                  toggleConfirmCancelModal={toggleConfirmCancelModal}
                  commentBodyError={editedCommentBodyError}
                  overallError={editedOverallError}
                  updateNotCreateNew={true}
                  label={`Comment Creation Station`}
                  userDatabaseData={userDatabaseData}
                />
              </div>
            </>
          }
        </div>
      </div>
      {/* Editor */}
      {auth && auth.id
        ?
        editorVisible
          ?
          <div className={`ml-0 sm:ml-4 pl-4 rounded-xl border-l-2 second-border`}>
            <div id={`add-tier-two-comment-index-${index}`} className={`adding-comment rounded-xl transition px-1 ml-0 mt-2
            sixth-text ${addExpandedClass}
          `}>
              <div className={`${levelThreeCommentOpacity} transition-to-opacity`}>
                <AddComment
                  handleCommentChange={handleTierThreeCommentChange}
                  togglePaymentModal={togglePaymentModal}
                  commentBody={tierThreeComment.tierThreeCommentBody}
                  toggleEditor={toggleEditor}
                  toggleConfirmCancelModal={toggleConfirmCancelModal}
                  commentBodyError={props.commentBodyError}
                  overallError={props.overallError}
                  label={`Comment Creation Station`}
                  userDatabaseData={userDatabaseData}
                />
              </div>
            </div>
          </div>
          :
          <div className={`ml-4 pl-0 sm:pl-4 rounded-xl border-l-2 second-border`}>
            <div id={`add-tier-two-comment-index-${index}`} className={`adding-comment rounded-xl transition px-1 mt-2
            sixth-text gradient-background-four`}>
            </div>
          </div>
        : <></>
      }
      {/* T3 comments */}
      {!tierThreeVisible
        ? <></>
        : !tierThreeIsLoaded
          ?
          <>
            <SkeletonLoaderForPosts />
          </>
          :
          <div className={`rounded-xl mb-4 transition-to-opacity py-px ml-0 sm:ml-4 pl-4 ${tierTwoCommentOpacity} border-l-2 second-border sixth-text`}>
            {tierThreeComments && tierThreeComments.map((t3comment, i) => {
              return (
                <TierThreeCommentsContainer
                  key={i}
                  index={i}
                  auth={auth}
                  tierOneCommentId={tierOneCommentId}
                  tierOneCommentIndex={tierOneCommentIndex}
                  tierTwoComment={comment}
                  tierTwoCommentIndex={index}
                  post={post}
                  comment={t3comment}
                  handleCommentChange={handleTierThreeCommentChange}
                  handleTierThreeCommentSubmit={handleTierThreeCommentSubmit}
                  addAndFindT3Comment={addAndFindT3Comment}
                  tierThreeOpacity={tierThreeOpacity}
                  addThumbsToTierThreeComment={addThumbsToTierThreeComment}
                  postCreatorHandle={postCreatorHandle}
                  userDatabaseData={userDatabaseData}
                  storeUserDatabaseData={storeUserDatabaseData}
                  goToUserProfilePage={goToUserProfilePage}
                  history={history}
                  updateT3CommentsArray={updateT3CommentsArray}
                  setTierThreeComments={setTierThreeComments}
                  updateTier1CommentsAndPostNumbersAfterT3Creation={updateTier1CommentsAndPostNumbersAfterT3Creation}
                  updateTier2CommentsData={updateTier2CommentsData}
                  toggleTipModalForT3Thumbs={props.toggleTipModalForT3Thumbs}
                  tierTwoParentCommentHandle={comment.commentCreatorHandle}
                />
              )
            })}
          </div>
      }
    </>
  )
}

export default TierTwoComments;