import React, { Component } from 'react';
import Modal from "react-modal";
import Moment from 'react-moment';
import { globals } from '../../config/globals';
import SkeletonLoaderSingleLine from '../Widgets/SkeletonLoaderSingleLine';
import { 
  Icon,
  starSolid, 
  closeCircleO, 
  bitcoinsvIcon
} from '../../config/icons';

class ArrayModal extends Component {
  render() {
    const {
      modalDataArray,
      modalArrayType,
      arrayModalIsLoading,
      arrayModalOpacity,
      noMoreResults,
      arrayIsLoading,
      refCallbackForArrayList,
      showModal,
      closeArrayModal,
      goToUserProfilePage,
      massTipAmount
    } = this.props
    const styles = {
      base: `array-modal-content rounded-xl first-text gradient-background-five shadow-effect-five
      mt-12 px-2 w-full sm:w-3/4 xl:w-1/2 transition-to-opacity`,
      afterOpen: 'opacity-100',
      beforeClose: 'opacity-0'
    }
    return (
      <>
        <Modal
          isOpen={showModal}
          shouldCloseOnOverlayClick={true}
          shouldCloseOnEsc={true}
          shouldReturnFocusAfterClose={true}
          className={styles}
          overlayClassName="array-modal-overlay"
          onRequestClose={closeArrayModal}
          closeTimeoutMS={300}
          contentLabel={'Data Modal'}
        >
          <div className={`rounded-xl px-1`}>
            <button onClick={closeArrayModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
            <div className={`clearfix `}>
              {/* Header div */}
              <div className={`mt-1 pb-2 ml-6 text-center text-3xl teko-font logo-style eleventh-text`}>
                {modalArrayType === 'SHARE_ARRAY'
                  ? <div>Sharers</div>
                  : modalArrayType === 'THUMBS_ARRAY'
                    ? <div>Thumbers</div>
                    : modalArrayType === 'PURCHASERS_ARRAY'
                      ? <div>Purchasers</div>
                      : modalArrayType === 'COMMENT_THUMBS_ARRAY'
                        ? <div>Comment Thumbers</div>
                        : modalArrayType === 'VOTERS_ARRAY'
                          ? <div>Poll Voters</div>
                          : modalArrayType === 'MASS_TIPPERS_ARRAY'
                            ? <div>Tip Recipients</div>
                            : modalArrayType === 'STAMPSHOTS_ARRAY'
                              ? <div>Stampshots</div>
                              : <></>
                }
              </div>
              {/* Line break */}
              <hr className={`sixth-border outsetBorder border mt-1 mb-4 opacity-50`}></hr>
              <div className={`transition-to-opacity ${arrayModalOpacity}`}>
              {arrayModalIsLoading
                ? <>
                  <SkeletonLoaderSingleLine />
                  <SkeletonLoaderSingleLine />
                  <SkeletonLoaderSingleLine />
                </>
                :
                <>
                  {/* If there is no data in the array to start */}
                  {modalDataArray && modalDataArray.length === 0
                    ?
                    <>
                      <div className={`rounded text-sm text-center my-3 p-1 eighth-text bg-transparent`}>
                        {globals.noContentAvailable}
                      </div>
                    </>
                    // If there is data in the array
                    :
                    <>
                      {/* Mapping the array */}
                      {modalDataArray && modalDataArray.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className={`flex justify-center text-center`}>
                              <div className={`px-2 mb-3 py-1 text-xs w-full md:w-4/5 2xl:w-2/3 rounded-lg first-text gradient-background shadow-effect-five`}>
                                {/* Top row (if applicable) This label might not be needed*/}
                                <div className={`flex flex-wrap justify-between w-full`}>
                                  {/* Left Corner */}
                                  <div className={`flex items-center rounded flex-wrap text-sm mr-1 py-2px`}>
                                    {/* Avatar */}
                                    <span className={`mr-1`} data-member='standard'>
                                      <div className={`p-2 -m-2 flex-shrink-0 ${item.actionTakerIsMember ? `flip-hover` : ``}`}>
                                        <img width='26' height='26'
                                          alt="Avatar"
                                          src={modalArrayType === 'SHARE_ARRAY'
                                            ? item.shareGiverAvatarURL
                                            : modalArrayType === 'THUMBS_ARRAY'
                                              ? item.thumbGiverAvatarURL
                                              : modalArrayType === 'PURCHASERS_ARRAY'
                                                ? item.purchaserAvatarURL
                                                : modalArrayType === 'COMMENT_THUMBS_ARRAY'
                                                  ? item.thumbGiverAvatarURL
                                                  : modalArrayType === 'VOTERS_ARRAY'
                                                    ? item.pollVoterAvatarURL
                                                    : modalArrayType === 'MASS_TIPPERS_ARRAY'
                                                      ? item.userAvatarURL
                                                      : modalArrayType === 'STAMPSHOTS_ARRAY'
                                                      ? item.stampShotCreatorAvatarURL
                                                      : <></>
                                          }
                                          className={`rounded-xl mx-auto ${item.actionTakerIsMember ? `shadow-effect-six` : ``}`}
                                          onError={({ currentTarget }) => {
                                            currentTarget.onerror = null;
                                            currentTarget.src = '/images/round-handcash-logo.png'
                                          }}
                                        >
                                        </img>
                                      </div>
                                    </span>
                                    <div className={`text-brand-green font-bold mr-1`}>
                                      {modalArrayType === 'SHARE_ARRAY'
                                        ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(item.valueOfShare).toFixed(2)}</span>
                                        : modalArrayType === 'THUMBS_ARRAY'
                                          ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(item.valueOfThumbs).toFixed(2)}</span>
                                          : modalArrayType === 'PURCHASERS_ARRAY'
                                            ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(item.valueOfPurchase).toFixed(2)}</span>
                                            : modalArrayType === 'COMMENT_THUMBS_ARRAY'
                                              ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(item.valueOfThumbs).toFixed(2)}</span>
                                              : modalArrayType === 'VOTERS_ARRAY'
                                                ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(item.valueOfVote).toFixed(3)}</span>
                                                : modalArrayType === 'MASS_TIPPERS_ARRAY'
                                                  ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(massTipAmount).toFixed(3)}</span>
                                                  : modalArrayType === 'STAMPSHOTS_ARRAY'
                                                  ? <span className={`overflow-hidden whitespace-nowrap`}>${parseFloat(item.valueOfStampshot).toFixed(2)}</span>
                                                  : <></>
                                      }
                                    </div>
                                    {/* Handle */}
                                    <div className={``}>
                                      <button className={`hover:text-brandGreen hover:underline cursor-pointer mr-1`}
                                        onClick={(e) => goToUserProfilePage(e,
                                          modalArrayType === 'SHARE_ARRAY'
                                            ? item.shareGiverHandle
                                            : modalArrayType === 'THUMBS_ARRAY'
                                              ? item.thumbGiverHandle
                                              : modalArrayType === 'PURCHASERS_ARRAY'
                                                ? item.purchaserHandle
                                                : modalArrayType === 'COMMENT_THUMBS_ARRAY'
                                                  ? item.thumbGiverHandle
                                                  : modalArrayType === 'VOTERS_ARRAY'
                                                    ? item.pollVoterHandle
                                                    : modalArrayType === 'MASS_TIPPERS_ARRAY'
                                                      ? item.userHandle
                                                      : modalArrayType === 'STAMPSHOTS_ARRAY'
                                                      ? item.stampShotCreatorHandle
                                                      : <></>
                                        )}>
                                        {modalArrayType === 'SHARE_ARRAY'
                                          ? <div className={`text-left`}>{item.shareGiverHandle}</div>
                                          : modalArrayType === 'THUMBS_ARRAY'
                                            ? <div className={`text-left`}>{item.thumbGiverHandle}</div>
                                            : modalArrayType === 'PURCHASERS_ARRAY'
                                              ? <div className={`text-left`}>{item.purchaserHandle}</div>
                                              : modalArrayType === 'COMMENT_THUMBS_ARRAY'
                                                ? <div className={`text-left`}>{item.thumbGiverHandle}</div>
                                                : modalArrayType === 'VOTERS_ARRAY'
                                                  ? item.pollVoterHandle
                                                  : modalArrayType === 'MASS_TIPPERS_ARRAY'
                                                    ? item.userHandle
                                                    : modalArrayType === 'STAMPSHOTS_ARRAY'
                                                    ? item.stampShotCreatorHandle
                                                    : <></>
                                        }
                                      </button>
                                    </div>
                                    {modalArrayType === 'VOTERS_ARRAY'
                                      ?
                                      <>
                                        <div className={`mr-1`}>voted: </div>
                                        <div className={`text-left underline text-underline-under font-bold`}>{item.pollOptionSelected}</div>
                                      </>
                                      : <></>
                                    }
                                  </div>
                                  {/* Right Corner */}
                                  <div className={`items-center flex py-2px`}>
                                    {/* If this is paywall and it has a rating */}
                                    {modalArrayType === 'PURCHASERS_ARRAY' && item.paywallRating && item.paywallRating > 0 && item.paywallRating < 6
                                      ?
                                      <div className={`flex items-center mr-1`}>
                                        {parseFloat(item.paywallRating)}
                                        <span className={`text-yellow-500 ml-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></span>'s
                                      </div>
                                      : <></>
                                    }
                                    <i className={`text-medium-gray`}>
                                      <Moment format="MM/DD/YYYY">{item.createdAt}</Moment>
                                    </i>
                                    {!item.blockchainPaymentData
                                      ? <></>
                                      :
                                      <>
                                        <a className='cursor-pointer hover:text-brandGreen text-xs sm:text-base ml-2'
                                          href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                          aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                                        >
                                          <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                        </a>
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* End Wrapper Div */}
                            {item.ref
                              ? <div ref={refCallbackForArrayList}></div>
                              : <></>
                            }
                            {item.lastItem
                              ? noMoreResults
                                ?
                                <></>
                                : arrayIsLoading
                                  ? <>
                                    <SkeletonLoaderSingleLine />
                                    <SkeletonLoaderSingleLine />
                                    <SkeletonLoaderSingleLine />
                                  </>
                                  : <></>
                              : <></>
                            }
                          </React.Fragment>
                        )
                      })}
                    </>
                  }
                </>
              }
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default ArrayModal