import React from 'react'
import Modal from "react-modal";
import NavAccordion from '../NavAccordion';
import { 
  Icon,
  closeCircleO, 
} from '../../config/icons';

const ChangeCategoryModal = props => {
  const {
    toggleCategoryModal,
    showCategoryModal,
    closeAccordion,
    accordionSelectedCategory,
    categoryHighlights,
    toggleAccordion,
    selectedAccordionItem
  } = props;
  const styles = {
    base: `array-modal-content first-text gradient-background-four shadow-effect-five
    mt-6 w-full xl:w-3/4 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showCategoryModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="array-modal-overlay"
        onRequestClose={toggleCategoryModal}
        closeTimeoutMS={300}
        contentLabel={'Select Category Modal'}
      >
        <div className={`text-center`}>
          <button onClick={toggleCategoryModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
            <Icon icon={closeCircleO} height={18} width={18} />
          </button>
          <div className={`teko-font text-4xl mb-1 logo-style ml-6`}>
            Category Selection
          </div>
          <hr className={`sixth-border outsetBorder border mb-3 opacity-50`}></hr>
          <NavAccordion 
            closeAccordion={closeAccordion}
            accordionSelectedCategory={accordionSelectedCategory}
            categoryHighlights={categoryHighlights}
            toggleAccordion={toggleAccordion}
            selectedAccordionItem={selectedAccordionItem}
          />
        </div>
      </Modal>
    </>
  )
}

export default ChangeCategoryModal;
