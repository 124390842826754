import React from 'react';
import TagsInput from './TagsInput'
import TitleInput from './TitleInput'
import DescriptionInput from './DescriptionInput'
import EditorButtons from './EditorButtons'
import { 
  Icon,
  closeIcon, 
} from '../../config/icons';
const EditorPageForPolls = props => {
  const {
    handleChange,
    categoryNameDisplay,
    isNewPost,
    editorOpacity,
    editorIsLoaded,
    goBackToCategoryPostList,
    togglePaymentModal,
    saveDraft,
    userDatabaseData,
    // Tag stuff
    tagInput,
    onKeyUp,
    addTagOnClick,
    onTagChange,
    onKeyDown,
    tags,
    removeTag,
    tagInputClass,
    // Poll
    pollData,
    handlePollOptionChange,
    handleAddPollOption,
    handleRemovePollOption,
    pollTextColor,
    // Stampshot
    handleStampshotCheckbox,
    stampshotAddIsChecked
  } = props
  const tx = document.getElementsByClassName("textarea-autosize");
  for (let i = 0; i < tx.length; i++) {
    tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
    tx[i].addEventListener("input", OnInput, false);
  }

  function OnInput() {
    this.style.height = 0;
    this.style.height = (this.scrollHeight) + "px";
  }
  return (
    <>
      {!editorIsLoaded
        ?
        <> </>
        :
        <main id='main-content' className={`flex -mt-2 rounded-xl mb-4 md:inline-flex mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`}>
          <div className={`w-full rounded-xl px-1 ${editorOpacity} transition-to-opacity`}>
            <div className={`pb-3 rounded-xl mb-2 transition first-text`}>
              <form>
                {/* Title Input */}
                <TitleInput
                  categoryNameDisplay={categoryNameDisplay}
                  handleChange={handleChange}
                  title={props.title}
                  titleError={props.titleError}
                  noDescription={false}
                />
                {/* Description Input */}
                <DescriptionInput
                  charCounterClass={props.charCounterClass}
                  description={props.description}
                  handleChange={handleChange}
                  descriptionError={props.descriptionError}
                />
                {/* Poll Options Input */}
                <div className={`sm:mx-2 mt-6 mb-3`}>
                  <div className={`transition flex items-center flex-wrap w-full first-text custom-input-container`} data-name="Options">
                    <div className={`w-full items-center`}>
                      {pollData.map((item, index) => {
                        return (
                          <div key={index} className={`flex items-center justify-between rounded-xl my-2 w-full`}>
                            <textarea className={`shadow-effect-ten textarea-autosize
                            ${index % 2 === 1 ? `gradient-background-seven` : `gradient-background-nine`}
                            border outline-none resize-none rounded-xl px-3 py-1 ${pollTextColor} transition mr-3 w-full`}
                              type='text'
                              rows='1'
                              name={`Option ${index + 1}`}
                              aria-label={`Add Poll Option`}
                              value={item.pollOption}
                              placeholder={`Option #${index + 1}`}
                              onChange={(e) => handlePollOptionChange(e, index)}
                              onKeyDown={e => {
                                if (e.key === 'Enter') {
                                  if (pollData.length === 6) return e.preventDefault()
                                  handleAddPollOption(e)
                                }
                                // if ((e.key === "Backspace" || e.key === "Delete") && item.pollOption.length === 0 && [2, 3, 4, 5].includes(index)) {
                                //   handleRemovePollOption(e, index)
                                // }
                              }}
                            />
                            <div className={`flex items-center`}>
                              {[2, 3, 4, 5].includes(index)
                                ?
                                <>
                                  <div className={`rounded-xl shadow-effect-five`}>
                                    <button onClick={(e) => handleRemovePollOption(e, index)}
                                      className={`flex justify-center items-center text-center transition rounded-xl button-ripple 
                                    text-red-600 gradient-background-twelve h-8 w-8 hover:text-red-700`}
                                    >
                                      <Icon icon={closeIcon} height={16} width={16} />
                                    </button>
                                  </div>
                                </>
                                : <></>
                              }
                              {/* Add Poll Option: Add the + button to the last item, but only if it applies  */}
                              {index === 0 && pollData.length < 6
                                ?
                                <>
                                  <div className={`rounded-xl shadow-effect-five`}>
                                    <button onClick={(e) => handleAddPollOption(e)}
                                      className={`items-center text-center transition rounded-xl button-ripple 
                                    text-brand-green font-bold gradient-background-twelve h-8 w-8`}
                                    >
                                      +
                                    </button>
                                  </div>
                                </>
                                : pollData.length === 6 && index === 0 ? <div className='h-8 w-8'></div> : <></>
                              }
                              {index === 1
                                ? <div className='h-8 w-8'></div>
                                : <></>
                              }
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/* Tags Input */}
                <TagsInput
                  tagInputClass={tagInputClass}
                  tagInput={tagInput}
                  onKeyDown={onKeyDown}
                  onKeyUp={onKeyUp}
                  onTagChange={onTagChange}
                  addTagOnClick={addTagOnClick}
                  tags={tags}
                  removeTag={removeTag}
                />
                <hr className={`sixth-border outsetBorder border mb-2 mx-2`}></hr>
                {/* Buttons */}
                <EditorButtons
                  isNewPost={isNewPost}
                  togglePaymentModal={togglePaymentModal}
                  savingDraft={props.savingDraft}
                  saveDraft={saveDraft}
                  goBackToCategoryPostList={goBackToCategoryPostList}
                  overallError={props.overallError}
                  toggleConfirmCancelModal={props.toggleConfirmCancelModal}
                  // Stampshots
                  isListOrPollCategory={true}
                  userDatabaseData={userDatabaseData}
                  stampshotAddIsChecked={stampshotAddIsChecked}
                  handleStampshotCheckbox={handleStampshotCheckbox}
                />
              </form>
            </div>
          </div>
        </main>
      }
    </>
  )
}

export default EditorPageForPolls;