// React & Redux
import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Redux functions
import { handleThemeChange } from './store/reducers/themeSlice';
import { opacityChange } from './store/reducers/opacitySlice';
import { logoutUser, storeUserDatabaseData } from './store/reducers/authSlice';

// Packages
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";

// Pages and Components
import MainPageContainer from './pages/Main/MainPageContainer';
import EditorPageContainer from './pages/Editor/EditorPageContainer';
import PostDetailPageContainer from './pages/PostDetails/PostDetailPageContainer';
import ProfilePageContainer from './pages/ProfilePage/ProfilePageContainer';
import FAQPage from './components/FAQPage';
import MembershipPage from './pages/Membership/MembershipPage';
import RankingPageContainer from './pages/Ranking/RankingPageContainer';
import NotificationContainer from './pages/Notification/NotificationContainer';
import MassTippingContainer from './pages/MassTipping/MassTippingContainer';
import AuthenticatePage from './pages/AuthenticatePage';
import DeclinePage from './pages/DeclinePage';
import SearchPage from './pages/SearchPage';
import NoMatch from './pages/NoMatch';

import Nav from './components/Nav';
import NavAccordion from './components/NavAccordion';
import { globals } from '../src/config/globals';
import API from './utils/API';
import LoginModal from './components/Modals/LoginModal';
import IntroModal from './components/Modals/IntroModal';
import PrivacyPage from './components/PrivacyPage';
import ChangeCategoryModal from './components/Modals/ChangeCategoryModal';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showLoginModal: false,
      showIntroModal: false,
      showCategoryModal: false,
      outlineClass: 'no-focus-outline',
      themeToggleDisabled: false,
      categoryHighlights: this.categoryHighlights,
      dropdownClass: '',
      selectedAccordionItem: null,
    };
  };
  // Redux checks this initially and removes the item if it's invalid, so this is okay if it exists. Important for rendering Nav.
  localStorageAuth = JSON.parse(localStorage.getItem('handCashAuthData'));
  cancelToken = API.CancelToken.source();
  categoryHighlights = [
    // href is used for checking if its a category page
    { selectedClass: '', href: 'prompt-games' }, // Prompt Games index 0
    { selectedClass: '', href: 'stamp-you' }, // Stamp You index 1
    { selectedClass: '', href: 'meet-the-stampers' }, // Meet the Stampers index 2
    // Entertainment +
    { selectedClass: '', href: '' }, // Entertainment + index 3
    { selectedClass: '', href: 'gaming' }, // Twitch Gaming index 4
    { selectedClass: '', href: 'music' }, // Spotify Soundcloud index 5
    { selectedClass: '', href: 'podcasts' }, // Podcasts index 6
    { selectedClass: '', href: 'teach' }, // Teach & Learn index 7
    { selectedClass: '', href: 'comedy' }, // Comedy index 8
    { selectedClass: '', href: 'sports' }, // Sports index 9
    { selectedClass: '', href: 'fitness' }, // Fitness index 10
    { selectedClass: '', href: 'cooking' }, // Cooking index 11
    { selectedClass: '', href: 'talent-showcase' }, // Talent Showcase index 12
    { selectedClass: '', href: 'animals' }, // Animals index 13
    { selectedClass: '', href: 'art' }, // Art index 14
    { selectedClass: '', href: 'beautiful-nature' }, // Beautiful Nature index 15
    { selectedClass: '', href: 'tiktok-playlist' }, // TikTok Playlist index 16
    { selectedClass: '', href: 'panoramic-reels' }, // Panoramic Reels index 17
    // Professional
    // { selectedClass: '', href: '' }, // Professional index 18
    // { selectedClass: '', href: 'entrepreneurship' }, // Entrepreneurship index 19
    // { selectedClass: '', href: 'ama' }, // AMA index 20
    // { selectedClass: '', href: 'career' }, // Business & Career index 21
    // { selectedClass: '', href: 'interviews' }, // Interviews index 22
    // { selectedClass: '', href: 'in-the-news' }, // In The News index 23
    // { selectedClass: '', href: 'bitcoin-businesses' }, // Bitcoin Businesses index 24
    // { selectedClass: '', href: 'promotions' }, // Promotions index 25
    // Knowledge
    { selectedClass: '', href: '' }, // Knowledge index 18
    { selectedClass: '', href: 'tutorials' }, // How To Tutorials index 19
    { selectedClass: '', href: 'articles' }, // Articles index 20
    { selectedClass: '', href: 'chatgpt' }, // ChatGPT & AI index 21
    { selectedClass: '', href: 'science' }, // Science & AI index 22
    { selectedClass: '', href: 'reviews' }, // Reviews index 23
    { selectedClass: '', href: 'life' }, // Life index 24
    { selectedClass: '', href: 'health' }, // Health index 25
    { selectedClass: '', href: 'code-share' }, // Code Share index 26
    { selectedClass: '', href: 'books' }, // Books index 27
    // Bonus
    { selectedClass: '', href: '' }, // bonus index 28
    { selectedClass: '', href: 'bitcoin-stories' }, // Bitcoin Stories index 29
    { selectedClass: '', href: 'polls' }, // Polls index 30
    { selectedClass: '', href: 'lists' }, // Lists index 31
    { selectedClass: '', href: 'questions' }, // Questions index 32
    { selectedClass: '', href: 'stories' }, // Stories index 33
    { selectedClass: '', href: 'letter-forever' }, // Letter Forever index 34
    { selectedClass: '', href: 'poetry' }, // Poetry index 35
    { selectedClass: '', href: 'bounties' }, // Bounty Challenges index 36
    { selectedClass: '', href: 'all' }, // All index 37
    { selectedClass: '', href: 'communities' }, // Communities index 38
  ]
  componentDidMount() {
    Modal.setAppElement("body");
    this.checkHandCashTokenValidity();
    this.checkIfCategoryPage();
    // this.checkIfUserVisitedBefore()
  };
  // checkIfUserVisitedBefore = () => {
  //   const visitedBefore = JSON.parse(localStorage.getItem('visitedBefore'))
  //   if (visitedBefore) this.setState({ userVisitedBefore: true })
  //   setTimeout(() => {
  //     localStorage.setItem('visitedBefore', true)
  //   }, 300)
  // }
  // Logs the user out if the localStorage is cleared.
  componentDidUpdate() {
    this.checkValidLocalStorage();
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled');
  };
  firstLoadUpdateArray = (items) => {
    if (!items || items.length === 0) return []
    let addingToArray = items;
    if (addingToArray.length === globals.fetchFifty) {
      let itemThirtyFive = addingToArray[34];
      itemThirtyFive.ref = true;
    }
    let lastItem = addingToArray[addingToArray.length - 1]
    lastItem.lastItem = true
    return addingToArray;
  }
  checkForMeetTheStampersPost = (userDatabaseData) => {
    if (userDatabaseData.postedInMeetTheStampers === true) return (
      this.props.history.push('/'),
      globals.toastError(toast, 'Only able to post once in Meet the Stampers')
    )
  }
  checkHandCashTokenValidity = async () => {
    const localStorageAuth = this.checkValidLocalStorage();
    if (localStorageAuth?.authToken) {
      API.verifyTokenAndGetDatabaseUserData(this.cancelToken.token)
        .then(res => {
          let userDatabaseData = res.data;
          if (userDatabaseData.followers.length > 0) {
            const followersUpdate = this.firstLoadUpdateArray(userDatabaseData.followers)
            userDatabaseData.followers = followersUpdate
          }
          if (userDatabaseData.following.length > 0) {
            const followingUpdate = this.firstLoadUpdateArray(userDatabaseData.following)
            userDatabaseData.following = followingUpdate
          }
          if (window.location.pathname === `/categories/meet-the-stampers/posts/new`) {
            this.checkForMeetTheStampersPost(userDatabaseData)
          }
          // Storing the user's database data in Redux.
          this.props.storeUserDatabaseData({ type: 'initialFetch', userDatabaseData: userDatabaseData });
          let referrerLink = document.referrer
          if (referrerLink.length === 0) referrerLink = 'no-referrer'
          try { API.addLoginAndReferrerLocation({ referrerLink: referrerLink }, this.cancelToken.token) }
          catch (err) { console.error(err) }
        })
        .catch(err => {
          if (err.message === 'Operation canceled') return
          console.error(err)
          if (err.response.data.message === 'Access to the platform has been disallowed. You have been logged out.'
            || err.response.data.message === 'Missing authorization. Retry HandCash login.') {
            return (
              this.props.logoutUser(),
              globals.toastError(toast, err.response.data.message),
              this.props.history.push(`/`)
            )
          }
        })
    }
    // If no token, or in other words, if the person is not logged in.
    else return
  }
  checkValidLocalStorage = () => {
    try {
      this.localStorageAuth = JSON.parse(localStorage.getItem('handCashAuthData'));
      return this.localStorageAuth;
    }
    catch (err) {
      return this.props.logoutUser();
    }
  }
  logoutUser = () => {
    // To prevent weird behavior on user's profile page, we redirect if they logout while they're on their own 
    if (this.props.location.pathname.includes('/profile/')) {
      let profileName = this.props.location.pathname
      let newProfileName = profileName.substring(9)
      if (this.localStorageAuth.handle === newProfileName) {
        this.props.opacityChange('removeProfilePageOpacity')
        return setTimeout(() => {
          this.props.logoutUser();
          this.removeActiveClassOfDropdown()
          this.props.history.push(`/`);
          globals.toastSuccess(toast, `Logout successful`);
        }, 300)
      }
    }
    // If they log out and they're on the home page, we redirect to stamp-you. Otherwise, the welcome page comes up.
    if (this.props.location.pathname === '/') {
      this.props.opacityChange('removeCategoryOpacity')
      this.removeActiveClassOfDropdown()
      globals.toastSuccess(toast, `Logout successful`);
      this.props.logoutUser();
      return this.props.history.push(`/categories/stamp-you`);
    }
    // If they are logging out on the Notifications Page
    if (this.props.location.pathname === '/notifications') {
      this.props.opacityChange('removeNotificationOpacity')
      this.removeActiveClassOfDropdown()
      globals.toastSuccess(toast, `Logout successful`);
      this.props.logoutUser();
      return this.props.history.push(`/`);
    }
    this.removeActiveClassOfDropdown()
    this.props.logoutUser();
    globals.toastSuccess(toast, `Logout successful`);
  }
  authenticateWithHandCash = (e) => {
    e.preventDefault();
    API.authenticateWithHandCash(this.cancelToken.token)
      .then(res => {
        const pathNameString = window.location.pathname
        const redirectionLoginUrl = res.data.redirectionLoginUrl
        const redirectWithPath = `${redirectionLoginUrl}&redirectLink=${pathNameString}`
        window.location.assign(redirectWithPath)
      })
      .catch(error => {
        if (error.message === 'Operation canceled') return
        console.error(error.response.data.message);
        globals.toastError(toast, error.response.data.message);
      });
  }
  toggleLoginModal = () => {
    this.removeActiveClassOfDropdown()
    this.setState(prevState => ({ showLoginModal: !prevState.showLoginModal }))
  };
  toggleAccordion = (e, index) => {
    if (e) e.preventDefault()
    if (this.state.selectedAccordionItem === index) return this.setState({ selectedAccordionItem: null })
    this.setState({ selectedAccordionItem: index })
  }
  closeAccordion = () => {
    this.setState({ selectedAccordionItem: null })
  };
  closeLoginModal = () => this.setState({ showLoginModal: false })
  closeIntroModal = () => this.setState({ showIntroModal: false })
  closeCategoryModal = () => this.setState({ showCategoryModal: false })
  // This function highlights the categories if the user manually enters a category page into their browser
  checkIfCategoryPage = () => {
    // If it doesn't include the categories path, we return out of the function
    if (!window.location.pathname.includes(`/categories/`)) return
    // If it is a category page, we highlight the category
    const path = window.location.pathname
    const selectedCategory = path.slice(12)
    let categoryHighlights = this.state.categoryHighlights
    for (let index = 0; index < categoryHighlights.length; index++) {
      if (categoryHighlights[index].href === selectedCategory) {
        // If index is 0 or 1 or All (44), we just highlight
        if (index === 0 || index === 1 || index === 2 || index === 44) {
          categoryHighlights[index].selectedClass = 'highlight-border'
          break
        }
        // Entertainment dropdown
        if (index >= 3 && index < 18) {
          categoryHighlights[index].selectedClass = 'highlight-border'
          categoryHighlights[3].selectedClass = 'highlight-border-dropdown'
          break
        }
        // Professional dropdown
        // if (index >= 17 && index < 25) {
        //   categoryHighlights[index].selectedClass = 'highlight-border'
        //   categoryHighlights[17].selectedClass = 'highlight-border-dropdown'
        //   break
        // }
        // Knowledge dropdown
        if (index >= 18 && index < 28) {
          categoryHighlights[index].selectedClass = 'highlight-border'
          categoryHighlights[18].selectedClass = 'highlight-border-dropdown'
          break
        }
        // Bonus dropdown
        if (index >= 28 && index < 37) {
          categoryHighlights[index].selectedClass = 'highlight-border'
          categoryHighlights[28].selectedClass = 'highlight-border-dropdown'
          break
        }
      }
    }
    return this.setState({ categoryHighlights: categoryHighlights })
  }
  highlightCategories = (index) => {
    let categoryHighlights = this.state.categoryHighlights
    categoryHighlights.forEach(category => {
      category.selectedClass = ''
    })
    if (index === undefined) return this.setState({ categoryHighlights: categoryHighlights })
    // If index is 0 or 1 or All (44), we just highlight
    if (index === 0 || index === 1 || index === 2 || index === 37) {
      categoryHighlights[index].selectedClass = 'highlight-border'
    }
    // Fun dropdown
    if (index >= 3 && index < 18) {
      categoryHighlights[index].selectedClass = 'highlight-border'
      categoryHighlights[3].selectedClass = 'highlight-border-dropdown'
    }
    // Professional dropdown
    // if (index >= 17 && index < 25) {
    //   categoryHighlights[index].selectedClass = 'highlight-border'
    //   categoryHighlights[17].selectedClass = 'highlight-border-dropdown'
    // }
    // Knowledge dropdown
    if (index >= 18 && index < 28) {
      categoryHighlights[index].selectedClass = 'highlight-border'
      categoryHighlights[18].selectedClass = 'highlight-border-dropdown'
    }
    // Bonus dropdown
    if (index >= 28 && index < 37) {
      categoryHighlights[index].selectedClass = 'highlight-border'
      categoryHighlights[28].selectedClass = 'highlight-border-dropdown'
    }
    this.setState({ categoryHighlights: categoryHighlights })
  }
  removeCategoryHighlights = () => {
    let categoryHighlights = this.state.categoryHighlights;
    categoryHighlights.forEach(category => {
      category.selectedClass = ''
    })
    this.setState({ categoryHighlights: categoryHighlights });
  }
  accordionSelectedCategory = (e, index, href) => {
    e.preventDefault();
    if (!this.props.auth?.id && window.location.pathname === '/' && href === 'stamp-you') return
    if (window.location.pathname === `/categories/${href}`) return
    this.closeAccordion();
    this.closeCategoryModal();
    this.highlightCategories(index)
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostDetailOpacity')
    this.props.opacityChange('removeFaqOpacity')
    this.props.opacityChange('removePostListOpacity')
    this.props.opacityChange('removeEditorOpacity')
    this.props.opacityChange('removeProfilePageOpacity')
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/categories/${href}`)
    }, 300)
  }
  selectedCategory = (e, index, href) => {
    e.preventDefault();
    if (!this.props.auth?.id && window.location.pathname === '/' && href === 'stamp-you') return globals.createRipple(e);
    if (index === 0 || index === 1 || index === 28) globals.createRipple(e);
    if (window.location.pathname === `/categories/${href}`) return this.removeActiveClassOfDropdown()
    this.closeAccordion();
    this.highlightCategories(index)
    this.removeActiveClassOfDropdown()
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostDetailOpacity')
    this.props.opacityChange('removeFaqOpacity')
    this.props.opacityChange('removePostListOpacity')
    this.props.opacityChange('removeEditorOpacity')
    this.props.opacityChange('removeProfilePageOpacity')
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/categories/${href}`)
    }, 300)
  }
  homePageClick = (e) => {
    e.preventDefault()
    if (window.location.pathname === '/') return this.removeActiveClassOfDropdown()
    if (window.location.pathname === `/categories/prompt-games` && !this.props.auth?.id) return
    this.closeAccordion()
    this.removeCategoryHighlights()
    this.removeActiveClassOfDropdown()
    this.props.opacityChange('removeCategoryOpacity')
    this.props.opacityChange('removePostDetailOpacity')
    this.props.opacityChange('removePostListOpacity')
    this.props.opacityChange('removeFaqOpacity')
    this.props.opacityChange('removeEditorOpacity')
    this.props.opacityChange('removeProfilePageOpacity')
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/`)
    }, 300)

  }
  faqPageClick = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    if (window.location.pathname === '/faq') return;
    this.removeCategoryHighlights()
    this.closeAccordion();
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/faq`);
    }, 300)
  }
  rankingPageClick = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    if (window.location.pathname === '/rankings') return;
    this.removeCategoryHighlights()
    this.closeAccordion();
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/rankings`);
    }, 300)
  }
  membershipPageClick = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    if (window.location.pathname === '/membership') return;
    this.removeCategoryHighlights()
    this.closeAccordion();
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/membership`);
    }, 300)
  }
  massTippingPageClick = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    if (window.location.pathname === '/mass-tipping') return;
    this.closeAccordion();
    this.removeCategoryHighlights()
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/mass-tipping`);
    }, 300)
  }
  notificationPageClick = (e) => {
    e.preventDefault()
    globals.createRipple(e)
    if (window.location.pathname === '/notifications') return;
    this.removeCategoryHighlights()
    this.closeAccordion();
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/notifications`);
    }, 300)
  }
  handleCheckedCheckbox = () => {
    if (!this.props.themeModeChecked) {
      this.setState({ themeToggleDisabled: true })
      document.documentElement.setAttribute('data-theme', 'LIGHT_THEME')
      this.props.handleThemeChange('LIGHT_THEME')
      setTimeout(() => {
        this.setState({ themeToggleDisabled: false })
      }, 400)
    }
    else {
      this.setState({ themeToggleDisabled: true })
      this.props.handleThemeChange('DARK_THEME');
      document.documentElement.setAttribute('data-theme', 'DARK_THEME')
      setTimeout(() => {
        this.setState({ themeToggleDisabled: false })
      }, 400)
    }
  };
  goToProfilePage = () => {
    this.removeActiveClassOfDropdown()
    // Measure to not reload page if user clicks on Profile name again while they're already on the page
    if (window.location.pathname === `/profile/${this.props.auth.handle}`) return
    // Directs user to the profile page
    this.closeAccordion()
    this.removeCategoryHighlights()
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    setTimeout(() => {
      this.props.history.push(`/profile/${this.props.auth.handle}`);
    }, 300)
  }
  privacyPageClick = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    if (window.location.pathname === `/privacy`) return
    this.closeAccordion()
    this.removeCategoryHighlights()
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    setTimeout(() => {
      this.props.history.push(`/privacy`);
    }, 300)
  }
  searchPageClick = (e) => {
    e.preventDefault()
    globals.createRipple(e)
    if (window.location.pathname === `/search`) return
    this.closeAccordion()
    this.removeCategoryHighlights()
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    setTimeout(() => {
      this.props.history.push(`/search`);
    }, 300)
  }
  waterCoolerClick = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    if (window.location.pathname === `/categories/water-cooler`) return
    this.closeAccordion()
    this.removeCategoryHighlights()
    this.props.opacityChange('removeCategoryOpacity');
    this.props.opacityChange('removePostDetailOpacity');
    this.props.opacityChange('removePostListOpacity');
    this.props.opacityChange('removeEditorOpacity');
    this.props.opacityChange('removeProfilePageOpacity');
    this.props.opacityChange('removeFaqOpacity');
    this.props.opacityChange('removeMassTippingOpacity');
    this.props.opacityChange('removeRankingPageOpacity');
    this.props.opacityChange('removeMembershipOpacity');
    this.props.opacityChange('removeSearchPageOpacity');
    this.props.opacityChange('removeNotificationOpacity');
    this.props.opacityChange('removePrivacyOpacity');
    setTimeout(() => {
      this.props.history.push(`/categories/water-cooler`);
    }, 300)
  }
  removeActiveClassOfDropdown = () => {
    document.querySelectorAll("[data-dropdown].active").forEach(dropdown => {
      dropdown.classList.remove('active')
    })
  }
  handleOnClickForDropdown = (e) => {
    // e.preventDefault();
    const isDropdownButtonOne = e.target.matches("[data-dropdown-button]")
    let isDropdownButtonTwo = false
    if (e.target.parentElement) isDropdownButtonTwo = e.target.parentElement.matches("[data-dropdown-button]")
    let isDropdownButton = false
    if ((isDropdownButtonOne === true)
      || (isDropdownButtonTwo === true)
    ) {
      isDropdownButton = true
    }
    // This is included because light/dark mode won't toggle if it preventsDefault. 
    // But this is needed for CodeEditor Dropdown clicks so it doesn't refresh
    if (isDropdownButton) e.preventDefault()
    // // If you're clicking outside the button, you want to close it. This ignores it
    if (!isDropdownButton && e.target.closest('[data-dropdown]') != null) return
    let currentDropdown
    if (isDropdownButton) {
      currentDropdown = e.target.closest('[data-dropdown]')
      currentDropdown.classList.toggle('active')
    }
    // This closes any active dropdowns, aside from within the dropdown itself. Need to close manually unfortunately.
    document.querySelectorAll("[data-dropdown].active").forEach(dropdown => {
      if (dropdown === currentDropdown) return
      dropdown.classList.remove('active')
    })
  }
  toggleIntroModal = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    this.setState(prevState => ({ showIntroModal: !prevState.showIntroModal }))
  }
  toggleCategoryModal = (e) => {
    e.preventDefault()
    this.removeActiveClassOfDropdown()
    this.setState(prevState => ({ showCategoryModal: !prevState.showCategoryModal }))
  }
  render() {
    const { themeModeChecked, auth, userDatabaseData } = this.props;
    // Listen to tab events to enable outlines (accessibility improvement)
    document.body.addEventListener('keyup', function (e) {
      if (e.key === 'Tab') /* tab */ {
        document.documentElement.classList.remove('no-focus-outline');
      }
    }, { once: true });
    // For DROPDOWN
     // For DROPDOWN
    document.addEventListener("click", this.handleOnClickForDropdown)
    return (
      <>
        <div className={`h-full standard-background transition`}>
          <div className={`standard-background transition `}>
            <a href='#main-content' aria-label='Skip Navigation'
              className={`skip-nav-link fifth-background first-text`}>
              Skip Navigation
            </a>
            <Nav
              themeModeChecked={themeModeChecked}
              handleCheckedCheckbox={this.handleCheckedCheckbox}
              selectedCategory={this.selectedCategory}
              faqPageClick={this.faqPageClick}
              rankingPageClick={this.rankingPageClick}
              notificationPageClick={this.notificationPageClick}
              massTippingPageClick={this.massTippingPageClick}
              privacyPageClick={this.privacyPageClick}
              membershipPageClick={this.membershipPageClick}
              homePageClick={this.homePageClick}
              searchPageClick={this.searchPageClick}
              waterCoolerClick={this.waterCoolerClick}
              toggleModal={this.toggleLoginModal}
              toggleIntroModal={this.toggleIntroModal}
              auth={auth}
              localStorageAuth={this.localStorageAuth}
              goToProfilePage={this.goToProfilePage}
              logoutUser={this.logoutUser}
              userDatabaseData={userDatabaseData}
              themeToggleDisabled={this.state.themeToggleDisabled}
              categoryHighlights={this.state.categoryHighlights}
              dropdownClass={this.state.dropdownClass}
              toggleCategoryModal={this.toggleCategoryModal}
            />
            <div className={`w-full md:flex md:justify-between`}>
              <div className={`sm:flex sm:justify-center mt-1 `}>
                {/* Below div is the one I removed the md: stuff for */}
                <div className={`w-full hidden sm:block sm:max-w-3xl md:hidden text-center nav-accordion-margin-top mb-4 sm:mb-2`}>
                  <NavAccordion
                    closeAccordion={this.closeAccordion}
                    accordionSelectedCategory={this.accordionSelectedCategory}
                    categoryHighlights={this.state.categoryHighlights}
                    toggleAccordion={this.toggleAccordion}
                    selectedAccordionItem={this.state.selectedAccordionItem}
                  />
                </div>
              </div>
              <Switch>
                <Route exact path='/' component={MainPageContainer} />
                <Route exact path='/categories/:categoryName' component={MainPageContainer} />
                <Route exact path='/profile/:handCashHandle' component={ProfilePageContainer} />
                <Route exact path='/faq' component={FAQPage} />
                <Route exact path='/rankings' component={RankingPageContainer} />
                <Route exact path='/mass-tipping' component={MassTippingContainer} />
                <Route exact path='/membership' component={MembershipPage} />
                <Route exact path='/privacy' component={PrivacyPage} />
                <Route exact path='/notifications' component={NotificationContainer} />
                <Route exact path='/search' component={SearchPage} />
                {/* Post Routes */}
                <Route exact path='/categories/:categoryName/posts/new' component={EditorPageContainer} />
                <Route exact path='/edit/:slug' component={EditorPageContainer} />
                <Route exact path='/draft-update/:draftSlug' component={EditorPageContainer} />
                <Route exact path='/posts/:slug' component={PostDetailPageContainer} />
                {/* HandCash */}
                <Route exact path='/authenticate' component={AuthenticatePage} />
                <Route exact path='/decline' component={DeclinePage} />
                <Route component={NoMatch} />
              </Switch>
            </div>

          </div>
          {/* Where a footer would go */}
        </div>
        <LoginModal
          showLoginModal={this.state.showLoginModal}
          closeLoginModal={this.closeLoginModal}
          authenticateWithHandCash={this.authenticateWithHandCash}
        />
        <IntroModal
          showIntroModal={this.state.showIntroModal}
          closeIntroModal={this.closeIntroModal}
          authenticateWithHandCash={this.authenticateWithHandCash}
          auth={auth}
        />
        <ToastContainer
          autoClose={3000}
        />
        <ChangeCategoryModal
          showCategoryModal={this.state.showCategoryModal}
          closeCategoryModal={this.closeCategoryModal}
          toggleCategoryModal={this.toggleCategoryModal}
          closeAccordion={this.closeAccordion}
          accordionSelectedCategory={this.accordionSelectedCategory}
          categoryHighlights={this.state.categoryHighlights}
          toggleAccordion={this.toggleAccordion}
          selectedAccordionItem={this.state.selectedAccordionItem}
        />
      </>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    themeModeChecked: state.theme.themeModeChecked,
    auth: state.auth.handCashLogInData,
    userDatabaseData: state.auth.userDatabaseData,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleThemeChange: (theme) => dispatch(handleThemeChange(theme)),
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue)),
    logoutUser: () => dispatch(logoutUser()),
    storeUserDatabaseData: (userData) => dispatch(storeUserDatabaseData(userData))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
