import React from 'react'
import Modal from "react-modal";
import { 
  Icon,
  closeCircleO, 
} from '../../config/icons';

const LoginModal = props => {
  const { showLoginModal, closeLoginModal, authenticateWithHandCash } = props;
  const styles = {
    base: `modal-content first-text gradient-background-four shadow-effect-five
    mt-32 w-full px-1 sm:w-9/12 xl:w-2/5 transition-to-opacity rounded-xl`,
    afterOpen: 'opacity-100',
    beforeClose: 'opacity-0'
  }
  return (
    <>
      <Modal
        isOpen={showLoginModal}
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        className={styles}
        overlayClassName="modal-overlay mr-4 sm:mr-0"
        onRequestClose={closeLoginModal}
        closeTimeoutMS={300}
        contentLabel={'HandCash Login Modal'}
      >
        <div className={`text-center pb-3`}>
          <button onClick={closeLoginModal} className={`float-right transition rounded-xl 
                shadow-effect-eight text-red-600 hover:bg-red-600 hover:text-black`}>
              <Icon icon={closeCircleO} height={18} width={18} />
            </button>
          <div className={`inline-flex justify-center teko-font logo-style second-text text-4xl -mt-1 mb-1 ml-6`}>
            Tip<span className={`eleventh-text mr-1`}>Stampers</span>
            <span>
              {/* <img className={`rounded-xl mt-2 mr-1`}
                width='32' height='32' alt="HC"
                src='/images/round-handcash-logo.png'
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = '/images/round-handcash-logo.png'
                }}
              /> */}
            </span>
            Login
          </div>
          <hr className={`sixth-border outsetBorder border my-1 opacity-50`}></hr>
          <div className={`mt-4`}>
            <button
              className={`font-header connectButtonNoImage`}
              onClick={(e) => authenticateWithHandCash(e)}
            >
              <span className={`text-sm sm:text-base`}>
                Login to HandCash
              </span>
            </button>
            <div className={`mt-3`}>
              <a href="https://market.handcash.io/"
                aria-label={`Go to the HandCash website to create an account if you do not have one.`}
                className={`fifth-hover text-sm sm:text-base hover:underline`}
                rel="noopener noreferrer">
                No HandCash account? Tap here to create one.
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default LoginModal;
