import React, { Component } from 'react'
import Moment from 'react-moment';
import { 
  Icon,
  bitcoinsvIcon, 
} from '../../config/icons';

class MassTippingPage extends Component {
  state = {
    commentView: '',
    commentOpacity: 'opacity-100'
  }
  // 'GET_LATEST' 'GET_HIGHEST'
  render() {
    const {
      array,
      title,
      goToUserProfilePage,
      auth,
      viewRecipientsArray
    } = this.props;
    return (
      <>
        <div className={`mb-3 pt-2 flex justify-center text-xl text-3xl teko-font logo-style eleventh-text`}>{title}</div>
        <div className={`px-2 mb-3 flex justify-center`}>
          <table aria-label='Donation Statistics Table' className={`shadow-effect-five text-sm items-center w-full md:w-auto`}>
            <thead><tr>
              <th className={`text-sm sm:text-base first-text eighth-background border sixth-border text-center px-1 sm:px-3 py-2`}>Total</th>
              <th className={`text-sm sm:text-base first-text eighth-background border sixth-border text-center px-1 sm:px-3 py-2`}>Tipper</th>
              <th className={`text-sm sm:text-base first-text eighth-background border sixth-border text-center px-1 sm:px-3 py-2`}>Recipients</th>
              <th className={`hidden sm:table-cell text-sm sm:text-base first-text eighth-background border sixth-border text-center px-1 sm:px-3 py-2`}>$ Per Each</th>
              <th className={`hidden sm:table-cell text-sm sm:text-base first-text eighth-background border sixth-border text-center px-1 sm:px-3 py-2`}>Tx</th>
              <th className={`hidden sm:table-cell text-sm sm:text-base first-text eighth-background border sixth-border text-center px-1 sm:px-3 py-2`}>Date</th>
            </tr></thead>
            {array && array.length === 0
              ?
              <tbody>
                <tr>
                  <td colSpan='6' className={`border sixth-border px-2 py-2 text-center`}>
                    No results to display at the moment
                  </td>
                </tr>
              </tbody>
              :
              <>
                {array && array.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <tbody>
                        <tr className={`${index % 2 !== 0 ? `gradient-background-two`: `gradient-background-stats-two`}`}>
                          {/* Total Amount */}
                          <td className={`border sixth-border px-2 py-2 text-center`}>
                            <div className={`text-brand-green font-bold`}>
                              ${item.totalAmountSent}
                            </div>
                          </td>
                          {/* Avatar Image & Handle */}
                          <td className={`border sixth-border px-2 py-2`}>
                            <div className={`h-auto text-center flex`}>
                              <span data-tooltip='Member' data-member='large' 
                                className={`${item.actionTakerIsMember && auth?.handle !== item.userHandle 
                                ? `member-tooltip-large ` : ``} mr-1`}
                              >
                                <div className={`p-2 -m-2 ${item.actionTakerIsMember ? `flip-hover` : ``}`}>
                                  <img width='28' height='28'
                                    alt="Avatar"
                                    src={item.userAvatarURL}
                                    className={`rounded-xl ${item.actionTakerIsMember ? `shadow-effect-six` : ``}`}
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = '/images/round-handcash-logo.png'
                                    }}
                                  >
                                  </img>
                                </div>
                              </span>
                                {/* User Handle */}
                            <button
                              onClick={(e) => goToUserProfilePage(e, item.userHandle)}
                              className={`fifth-hover hover:underline break-all`}
                            >
                              {item.userHandle}
                            </button>
                            </div>
                          
                          </td>
                          {/* Recipients */}
                          <td className={`border sixth-border px-2 py-2 text-center`}>
                            <button
                              onClick={(e) => viewRecipientsArray(e, item.tippedList, item.massTipAmount)}
                              className={`fifth-hover hover:underline break-all`}
                            >
                              {item.tippedList.length}
                            </button>
                          </td>
                          {/* Per Each */}
                          <td className={`hidden sm:table-cell border sixth-border px-2 py-2 text-center`}>
                            <div className={`text-brand-green font-bold`}>
                              ${parseFloat(item.massTipAmount).toFixed(3)}
                            </div>
                          </td>
                          {/* BSV tx, if applicable */}
                          <td className={`hidden sm:table-cell border sixth-border px-2 py-2 text-center `}>
                            <a className='cursor-pointer hover:text-brandGreen text-xs sm:text-base'
                              href={`https://whatsonchain.com/tx/${item.blockchainPaymentData.transactionId}`}
                              rel="noopener noreferrer"
                              target="_blank"
                              aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                            >
                              <span className={`inline-block align-middle h-5`}>
                                <span className={`mr-1`}>
                                  <Icon icon={bitcoinsvIcon} height={18} width={18} />
                                </span>
                              </span>
                            </a>
                          </td>
                          {/* Date */}
                          <td className={`hidden sm:table-cell border sixth-border px-2 py-2 text-center text-xs`}>
                            <i><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></i>
                          </td>
                        </tr>
                      </tbody>
                      {/* Message on new line */}
                      {item.massTipMessage
                        ?
                        <>
                          <tbody><tr><td colSpan="6" className={`border sixth-border px-2 py-2 break-words ${index % 2 !== 0 ? `gradient-background-two`: `gradient-background-stats-two`}`}>
                            {item.massTipMessage}
                          </td></tr></tbody>
                          <tbody><tr><td colSpan="6" className={`border sixth-border h-1 table-fill`}></td></tr></tbody>
                        </>
                        : <></>
                      }
                    </React.Fragment>
                  )
                })}
              </>
            }
          </table>
        </div>
      </>
    )
  }
}

export default MassTippingPage;