import React, { Component } from 'react';
import parse from 'html-react-parser';
import CommentListItemContainer from '../../components/Comments/CommentListItemContainer';
import AddComment from '../../components/Comments/AddComment';
import ThumbsComponent from './ThumbsComponent';
import ShareComponent from './ShareComponent';
import PollComponent from './PollComponent';
import PaywallComponent from './PaywallComponent';
import StampshotComponent from './StampshotComponent';
import StaticCodeEditor from './StaticCodeEditor';
import SkeletonLoaderForPostDetail from '../../components/Widgets/SkeletonLoaderForPostDetail';
import SkeletonLoaderForPosts from '../../components/Widgets/SkeletonLoaderForPosts';
import Moment from 'react-moment';
import {
  Icon,
  eyeSolid,
  bxKey,
  createOutline,
  commentsIcon,
  thumbsUp,
  shareSquare,
  clockIcon,
  alarmClockLine,
  vote20Regular,
  bitcoinsvIcon,
  cameraLock,
  bulletListLine,
  starSolid
} from '../../config/icons';
import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  TumblrShareButton,
  TumblrIcon,
  // LinkedinShareButton,
  // LinkedinIcon
} from 'react-share';

class PostDetailPage extends Component {
  render() {
    const {
      title,
      freeContent,
      paywallContent,
      numPaywallPurchases,
      paywallCost,
      postHasPaywall,
      codeEditorContent,
      codeEditorLanguage,
      id,
      slug,
      // tags,
      numComments,
      numThumbs,
      totalValueReceived,
      numShares,
      numViews,
      createdAt,
      category,
      categoryDisplayName,
      postCreatorHandCashId,
      postCreatorHandle,
      postCreatorAvatarURL,
      // numberOfPaywallRatings,
      ratingAverage,
      actionTakerIsMember,
      readTime,
      // Stampshots and Votes
      stampshotTime,
      stampshots,
      isFrozen,
      pollData,
      numPollVotes,
      description,
      numStampshots
    } = this.props.post;
    const { commentBody } = this.props.comment
    const {
      handleCommentChange,
      togglePaymentModal,
      commentsArray,
      toggleEditor,
      setPostComments,
      setTierTwoCommentNumber,
      addExpandedClass,
      updatePostAndCommentDataAfterT2Comment,
      refCallback,
      moreCommentsLoading,
      noMoreComments,
      postDetailPageIsLoading,
      postDetailOpacity,
      tierOneCommentOpacity,
      themeforCodeEditor,
      auth,
      userDatabaseData,
      userAlreadyThumbsPost,
      userAlreadyPurchasedPaywall,
      userAlreadySharedPost,
      goToUserProfilePage,
      clickToEditPost,
      toggleLoginModal,
      paywallOpacity,
      userRatedPaywall,
      handleStarChange,
      disableRating,
      toggleArrayModal,
      commentsAreLoading,
      commentsOpacity,
      globals,
      toggleTipModal,
      tierOneEditorVisible,
      toggleConfirmCancelModal,
      dateInterval,
      // Votes
      userAlreadyVoted,
      viewVotes,
      toggleViewVotes,
      handleRadioButtonSelection,
      isRadioButtonSelected,
      barChartData,
      pieChartData,
      // View
      setView,
      commentViewSelected,
      listViewSelected,
      listOrCommentView,
    } = this.props;
    const shareUrl = `https://tipstampers.com/posts/${slug}`;
    const shareTitle = `${title} by ${postCreatorHandle}`;
    return (
      <>
        {postDetailPageIsLoading
          ? <div className={`w-full sm:max-w-3xl md:max-w-4xl rounded-xl mx-auto`}><SkeletonLoaderForPostDetail /></div>
          :
          <main id='main-content' className={`flex md:inline-flex mx-auto w-full sm:max-w-3xl md:max-w-4xl transition -mt-1 sm:mt-1`}>
            <div className={`w-full rounded px-1 transition-to-opacity ${postDetailOpacity}`}>
              {/* If Stampshot time stuff, we have its own row */}
              {stampshotTime && (['polls', 'lists', 'questions'].includes(category))
                ?
                <>
                  <div className={`rounded-xl mb-1 transition first-text`}>
                    {/* If clock has expired, we display different content. Closed is for people being on page as deadline approaches. This is for frozen already */}
                    {isFrozen && dateInterval !== 'Stampshot Submissions Frozen' && stampshots.length > 0 && stampshots[0].blockchainPaymentData
                      ?
                      <>
                        <div className={`rounded-xl -mt-3 mb-6`}>
                          <div className={`flex justify-center logo-style eleventh-text teko-font text-3xl text-center
                            underline text-underline-under`}>
                            Submissions Frozen
                          </div>
                          <div className={`flex items-center justify-center w-full pb-1`}>
                            <a className={`first-text px-2 py-1 text-underline-under hover:underline flex justify-center items-center hover:text-brandGreen text-sm`}
                              href={`https://whatsonchain.com/tx/${stampshots[0].blockchainPaymentData.transactionId}`}
                              rel="noopener noreferrer"
                              target="_blank"
                              aria-label='View Bitcoin transaction in different tab on whatsonchain.com'
                            >
                              <div className={`mr-1 flex items-center`}>View Stampshot <Icon icon={cameraLock} height={18} width={18} /> on the Blockchain</div>
                              <Icon icon={bitcoinsvIcon} height={18} width={18} />
                            </a>
                          </div>
                        </div>
                      </>
                      // For stampshots that freeze when user is on the page
                      : dateInterval === 'Stampshot Submissions Frozen' || isFrozen || stampshots.length > 0
                        ?
                        <>
                          <div className={`rounded-xl -mt-3 mb-6`}>
                            <div className={`logo-style eleventh-text teko-font text-3xl text-center mb-1
                            underline text-underline-under`}>
                              Submissions Frozen
                            </div>
                          </div>
                        </>
                        // For active stampshots with countdown
                        :
                        <>
                          <div className={`rounded-xl -mt-3 mb-6`}>
                            <div className={`logo-style eleventh-text teko-font text-3xl text-center mb-1
                            underline text-underline-under`}>
                              Stampshot Countdown Clock
                            </div>
                            <div className={`text-center flex items-center justify-center`}>
                              <div className={`flex items-center rounded-xl`}>
                                <span className={`logo-style teko-font text-xl`}>
                                  {dateInterval}
                                </span>
                                <span className={`ml-1 mb-1 text-sm`}>left for entries</span>
                                <span className={`ml-2px -mt-px`}><Icon icon={alarmClockLine} height={17} width={17} /></span>
                              </div>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                </>
                // If it is not a stampshot post
                : <></>
              }
              {/* Top Row */}
              <div className={`p-2 mb-8 rounded-xl transition first-text gradient-background-two shadow-effect-one`}>
                <div className={`text-sm flex flex-wrap justify-between py-px`}>
                  {/* Top left */}
                  <div className={`flex items-center`}>
                    <span className={`${actionTakerIsMember && userDatabaseData?.handCashHandle !== postCreatorHandle ? `member-tooltip ` : ``}`} data-member='standard' data-tooltip='Member'>
                      <div className={`p-2 -m-2 ${actionTakerIsMember ? `flip-hover` : ``}`}>
                        <img width='20' height='20'
                          alt="Avatar"
                          src={postCreatorAvatarURL}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = '/images/round-handcash-logo.png'
                          }}
                          className={`rounded-xl mt-px ${actionTakerIsMember ? `shadow-effect-six` : ``}`}>
                        </img>
                      </div>
                    </span>
                    <div className={`ml-1 flex flex-wrap`}>
                      <button className={`fifth-hover text-underline-under hover:underline mr-3px`} onClick={(e) => goToUserProfilePage(e, postCreatorHandle)}>
                        {postCreatorHandle}
                      </button>
                      <div className={`text-medium-gray-lighter mr-3px`}>earned</div>
                      <div className={`fifth-text font-bold mr-3px`}>${totalValueReceived}</div>
                    </div>
                  </div>
                  {/* Top right */}
                  <div className={`text-medium-gray-lighter flex`}>
                    <div className={`flex items-center text-xs`}>
                      {/* Date */}
                      <Moment fromNow>
                        {new Date(createdAt)}
                      </Moment>
                      {/* Edit Button */}
                      {auth && postCreatorHandCashId === auth.id
                        ?
                        <>
                          <div className='mx-1'>|</div>
                          <button onClick={(e) => clickToEditPost(e, slug, this.props.post)}
                            className={`flex items-center sixth-hover`}>
                            <div className={``}>
                              <Icon icon={createOutline} height={18} width={18} />
                            </div>
                          </button>
                        </>
                        : <></>
                      }
                    </div>
                  </div>
                </div>
                <hr className={`sixth-border outsetBorder border mt-2 mb-3 opacity-50`}></hr>
                {/* Title */}
                <div className={`flex justify-center`}>
                  <h2 className={`text-center overflow-hidden post-detail-title mb-1 transition font-header eleventh-text`}>{title}</h2>
                </div>
                {/* Description*/}
                {!description ? <></>
                  : <>
                    <div className={`p-2 text-medium-gray-lighter italic flex justify-center rounded-lg text-sm sm:text-base transition mx-1`}>
                      {description}
                    </div>
                  </>
                }
                {/* Tags muddy up the post, and they're not totally needed. So I'm keeping them out. */}
                {/* If they need to be added, this is probably how it'll be done. Or at the bottom of the post. Only choices. */}
                {/* {tags && tags.length > 0
                  ?
                  <>
                    <hr className={`sixth-border outsetBorder border my-2 opacity-50`}></hr>
                    <div className={`flex text-medium-gray-lighter text-xs flex-wrap justify-center -mt-2px`}>
                      {tags.map((tag, index) => {
                        return (
                          <React.Fragment key={index}>
                            <div className={`mx-1`}>#{tag}</div>
                          </React.Fragment>
                        )
                      })}
                    </div>
                  </>
                  : <></>
                } */}
              </div>
              {/* Body */}
              <div className={`px-3 pt-2 ${(['lists'].includes(category) || !freeContent) ? `pb-4` : `pb-6`} mt-4 rounded-xl transition first-text post-detail-background shadow-effect-one`}>
                <div className={`flex flex-wrap items-center justify-center text-medium-gray-lighter text-xs pt-px pb-1`}>
                  <div className={`flex flex-wrap`}>
                    {/* Views */}
                    <div className={`flex items-center`} >
                      <div className={`mr-px -mt-px`}>
                        <Icon icon={eyeSolid} height={17} width={16} />
                      </div>
                      <div className={`ml-px mr-2`}>{numViews} View{numViews === 1 ? '' : 's'}</div>
                    </div>
                    {/* Thumbs */}
                    <div className={`flex items-center`}>
                      <div className={`mr-px -mt-2px`}>
                        <Icon icon={thumbsUp} height={12} width={12} />
                      </div>
                      <div className={`ml-px mr-2`}>{numThumbs} Thumb{numThumbs === 1 ? '' : 's'}</div>
                    </div>
                    {/* Comment */}
                    <div className={`flex items-center`}>
                      <div className={`mr-px -mt-px`}>
                        <Icon icon={commentsIcon} height={15} width={15} />
                      </div>
                      <div className={`ml-px mr-2`}>{numComments} Comment{numComments === 1 ? '' : 's'}</div>
                    </div>
                    {/* Number of paywall purchases and rating */}
                    {postHasPaywall
                      ?
                      <>
                        <div className={`flex items-center`}>
                          <div className={`mr-px -mt-px`}>
                            <Icon icon={bxKey} height={17} width={16} />
                          </div>
                          <div className={`ml-px mr-2`}>{numPaywallPurchases} Unlock{numPaywallPurchases === 1 ? `` : `s`}</div>
                          {/* Paywall Rating */}
                          {ratingAverage > 0
                            ?
                            <>
                              <div className={`mr-2 flex items-center`}>
                                <div className={`text-yellow-500 mx-px mb-px`}><Icon icon={starSolid} height={13} width={13} /></div>
                                {parseFloat(ratingAverage).toFixed(2)} Rating
                              </div>
                            </>
                            : <></>
                          }
                        </div>
                      </>
                      : <></>
                    }
                    {/* Shares */}
                    {categoryDisplayName === 'Water Cooler'
                      ? <></>
                      :
                      <div className={`flex items-center`}>
                        <div className={`mr-px -mt-px`}>
                          <Icon icon={shareSquare} height={13} width={13} />
                        </div>
                        <div className={`ml-px mr-2`}>{numShares} Share{numShares === 1 ? '' : 's'}</div>
                      </div>
                    }
                    {/* Stampshots */}
                    <div className={`flex items-center`}>
                      <div className={`mr-px -mt-px`}>
                        <Icon icon={cameraLock} height={13} width={13} />
                      </div>
                      <div className={`ml-px mr-2`}>{numStampshots} Stampshot{numStampshots === 1 ? '' : 's'}</div>
                    </div>
                    {/* Poll Votes */}
                    {category === 'polls'
                      ?
                      <div className={`flex items-center`}>
                        <div className={`mr-px -mt-px`}>
                          <Icon icon={vote20Regular} height={13} width={13} />
                        </div>
                        <div className={`ml-px mr-2`}>{numPollVotes} Vote{numPollVotes === 1 ? '' : 's'}</div>
                      </div>
                      : <></>
                    }
                    {!['polls', 'lists', 'questions'].includes(category) && readTime?.length > 0
                      ? <div className={`flex items-center`}>
                        <div className={`mr-1`}>
                          <Icon icon={clockIcon} height={12} width={12} />
                        </div>
                        <div className={`mr-1`}>
                          {readTime}
                        </div>
                      </div>
                      : <></>
                    }
                    <div className={`text-medium-gray-lighter mr-1`}>- <span className={`font-bold`}>#</span>{categoryDisplayName}</div>
                  </div>
                </div>
                {['lists'].includes(category) && !freeContent
                  ? <div className={`-mb-3`}></div>
                  : <hr className={`sixth-border outsetBorder border mt-1 mb-6 opacity-50`}></hr>
                }
                {/* Free Content, not included if lists or polls */}
                {['polls', 'lists'].includes(category) && !freeContent
                  ? <></>
                  : <div className={`parsed-editor-data`}>{parse(freeContent)}</div>
                }
                {/* Poll Stuff Goes here */}
                {category === 'polls'
                  ? <PollComponent
                    auth={auth}
                    userDatabaseData={userDatabaseData}
                    togglePaymentModal={togglePaymentModal}
                    userAlreadyVoted={userAlreadyVoted}
                    postCreatorHandCashId={postCreatorHandCashId}
                    viewVotes={viewVotes}
                    numPollVotes={numPollVotes}
                    pollData={pollData}
                    toggleViewVotes={toggleViewVotes}
                    handleRadioButtonSelection={handleRadioButtonSelection}
                    isRadioButtonSelected={isRadioButtonSelected}
                    toggleArrayModal={toggleArrayModal}
                    isFrozen={isFrozen}
                    barChartData={barChartData}
                    pieChartData={pieChartData}
                  />
                  : <></>
                }
                {/* CodeEditorContent if applicable */}
                {codeEditorContent
                  ?
                  <div className={``}>
                    <StaticCodeEditor
                      codeEditorContent={codeEditorContent}
                      codeEditorLanguage={codeEditorLanguage}
                      themeforCodeEditor={themeforCodeEditor}
                    />
                  </div>
                  : <></>
                }
                {/* Paywall Component - also conditional renders the bottom of the page with the hr */}
                <div className={`${paywallOpacity} transition-to-opacity`}>
                  <PaywallComponent
                    postHasPaywall={postHasPaywall}
                    auth={auth}
                    userDatabaseData={userDatabaseData}
                    postCreatorHandCashId={postCreatorHandCashId}
                    userAlreadyPurchasedPaywall={userAlreadyPurchasedPaywall}
                    paywallContent={paywallContent}
                    paywallCost={paywallCost}
                    numPaywallPurchases={numPaywallPurchases}
                    togglePaymentModal={togglePaymentModal}
                    toggleLoginModal={toggleLoginModal}
                    userRatedPaywall={userRatedPaywall}
                    handleStarChange={handleStarChange}
                    disableRating={disableRating}
                    ratingAverage={ratingAverage}
                    category={category}
                  />
                </div>
                {/* Bottom */}
              </div>
              {/* ACTION ROW*/}
              <div className={`px-2 my-4 rounded-xl transition first-text`}>
                <div className={`text-sm pb-px`}>
                  {/* Comments/Thumbs/Shares/Stampshot row */}
                  <div className={`flex justify-center flex-wrap items-center -mx-2 mt-6`}>
                    {/* Comment on Post */}
                    {isFrozen && category.includes(globals.listCategoryArray) // No list entries if frozen
                      ? <></>
                      :
                      <div className={`mx-2px mb-2`}>
                        {auth?.id
                          ?
                          <button className={`gradient-background-green text-white border-brand-green
                            items-center flex rounded-xl px-6px font-header button-ripple border-2 offsetBorder`}
                            onClick={(e) => toggleEditor(e)}>
                            <Icon icon={commentsIcon} height={15} width={15} />
                            <span className={`ml-2px`}>
                              {category.includes(globals.listCategoryArray) ? `Add Entry` : `Comment`}
                            </span>
                          </button>
                          :
                          <button className={`gradient-background-green text-white border-brand-green
                          items-center flex rounded-xl px-6px font-header button-ripple border-2 offsetBorder`}
                            onClick={toggleLoginModal}>
                            <Icon icon={commentsIcon} height={15} width={15} />
                            <span className={`ml-2px`}>
                              {category.includes(globals.listCategoryArray) ? `Login to Submit` : `Login to Comment`}
                            </span>
                          </button>
                        }
                      </div>
                    }
                    {/* Thumbs Post */}
                    <ThumbsComponent
                      numThumbs={numThumbs}
                      auth={auth}
                      userDatabaseData={userDatabaseData}
                      togglePaymentModal={toggleTipModal}
                      userAlreadyThumbsPost={userAlreadyThumbsPost}
                      postCreatorHandCashId={postCreatorHandCashId}
                    />
                    {/* Share Post */}
                    {categoryDisplayName === 'Water Cooler'
                      ? <></>
                      :
                      <ShareComponent
                        numShares={numShares}
                        auth={auth}
                        userDatabaseData={userDatabaseData}
                        togglePaymentModal={togglePaymentModal}
                        userAlreadySharedPost={userAlreadySharedPost}
                        postCreatorHandCashId={postCreatorHandCashId}
                      />
                    }
                    <StampshotComponent
                      auth={auth}
                      togglePaymentModal={togglePaymentModal}
                    />
                    {/* Direct Tip User */}
                    {/* {auth?.id
                      // If it's their own post
                      ? userDatabaseData && userDatabaseData._id === postCreatorDatabaseId
                        ? <></>
                        :
                        <>
                          <div className={`md:-mr-16 my-2`}>
                            <button className={`gradient-background-green text-white border-brand-green
                            items-center flex rounded-xl px-2 font-header button-ripple border-2 offsetBorder`}
                              onClick={(e) => toggleTipModal(e)}>
                              <div className={`h-14px  mr-px`}><Icon icon={bitcoinsvIcon} height={13} width={13} /></div>
                              <span className={`ml-px`}>Tip</span>
                            </button>
                          </div>
                        </>
                      // If they're not logged in
                      : <></>
                    } */}
                  </div>
                  {categoryDisplayName === 'Water Cooler'
                    ? <></>
                    : <>
                      <div className={`flex items-center flex-wrap justify-center ${isFrozen ? `py-2` : ``}`}>
                        <span className={`mx-1`}>
                          <FacebookShareButton
                            url={shareUrl}
                            quote={shareTitle}
                            hashtag={`#${globals.platformName}`}
                          >
                            <FacebookIcon
                              size={22}
                              round={true}
                            />
                          </FacebookShareButton>
                        </span>
                        <span className={`mx-1`}>
                          <TwitterShareButton
                            url={shareUrl}
                            title={shareTitle}
                            hashtag={`#${globals.platformName}`}
                          >
                            <TwitterIcon
                              size={22}
                              round={true}
                            />
                          </TwitterShareButton>
                        </span>
                        <span className={`mx-1`}>
                          <RedditShareButton
                            url={shareUrl}
                            title={shareTitle}
                          >
                            <RedditIcon
                              size={22}
                              round={true}
                            />
                          </RedditShareButton>
                        </span>
                        <span className={`mx-1`}>
                          <TumblrShareButton
                            url={shareUrl}
                            title={shareTitle}
                          >
                            <TumblrIcon
                              size={22}
                              round={true}
                            />
                          </TumblrShareButton>
                        </span>
                      </div>
                    </>
                  }
                  {/* Arrays */}
                  <div className={`pb-1 mb-1 text-sm flex flex-wrap justify-center`}>
                    {numThumbs > 0
                      ?
                      <div className={`mx-1 mt-1 mb-2`}>
                        <button onClick={(e) => toggleArrayModal(e, "THUMBS_ARRAY")}
                          className={`font-header shadow-effect-seven
                          eighth-text gradient-background-twelve
                          items-center flex button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                          <div className={`-mt-px`}>
                            <Icon icon={thumbsUp} height={12} width={12} />
                          </div><span className={`ml-px`}>Thumbers</span>
                        </button>
                      </div>
                      : <></>
                    }
                    {categoryDisplayName !== 'Water Cooler' && numShares > 0
                      ?
                      <div className={`mx-1 mt-1 mb-2`}>
                        <button onClick={(e) => toggleArrayModal(e, "SHARE_ARRAY")}
                          className={`font-header shadow-effect-seven
                          eighth-text gradient-background-twelve
                          items-center flex button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                          <Icon icon={shareSquare} height={13} width={13} /><span className={`ml-px`}>Sharers</span>
                        </button>
                      </div>
                      : <></>
                    }
                    {postHasPaywall && numPaywallPurchases > 0
                      ?
                      <div className={`mx-1 mt-1 mb-2`}>
                        <button onClick={(e) => toggleArrayModal(e, "PURCHASERS_ARRAY")}
                          className={`font-header shadow-effect-seven
                          eighth-text gradient-background-twelve
                          items-center flex button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                          <Icon icon={bxKey} height={17} width={16} /><span className={`ml-px`}>Purchasers</span>
                        </button>
                      </div>
                      : <></>
                    }
                    {categoryDisplayName !== 'Water Cooler' && numStampshots > 0
                      ?
                      <div className={`mx-1 mt-1 mb-2`}>
                        <button onClick={(e) => toggleArrayModal(e, "STAMPSHOTS_ARRAY")}
                          className={`font-header shadow-effect-seven
                          eighth-text gradient-background-twelve
                          items-center flex button-ripple transition rounded-xl cursor-pointer py-px px-2 category-hover`}>
                          <Icon icon={cameraLock} height={17} width={16} /><span className={`ml-px`}>Stampshots</span>
                        </button>
                      </div>
                      : <></>
                    }
                  </div>
                </div>
                {/* This is the ending div for the action row.*/}
              </div>
              {auth && auth.id
                ? tierOneEditorVisible
                  ?
                  <>
                    <hr className={`sixth-border outsetBorder border -mt-2 mb-2 mx-2 opacity-50`}></hr>
                    <div id='add-comment' className={`adding-comment px-1 rounded-xl transition
                      sixth-text ${addExpandedClass ? `` : ``} ${addExpandedClass}`}
                    >
                      <div className={`transition-to-opacity ${tierOneCommentOpacity}`}>
                        <AddComment
                          handleCommentChange={handleCommentChange}
                          togglePaymentModal={togglePaymentModal}
                          commentBody={commentBody}
                          toggleConfirmCancelModal={toggleConfirmCancelModal}
                          commentBodyError={this.props.commentBodyError}
                          overallError={this.props.overallError}
                          auth={auth}
                          label={category.includes(globals.listCategoryArray) ? `List Entry` : `Comment Creation Station`}
                          userDatabaseData={userDatabaseData}
                        />
                      </div>
                    </div>
                  </>
                  : <></>
                : <></>
              }
              {/* Don't display comments if there aren't any */}
              {commentsAreLoading
                ?
                <>
                  <SkeletonLoaderForPosts />
                  <SkeletonLoaderForPosts />
                </>
                : <>
                  <div className={`py-px sm:pb-3 mb-4 rounded-xl transition first-text ${commentsOpacity}`}>
                    {numComments === 0
                      ? isFrozen
                        ?
                        <div className={`text-center text-medium-gray-lighter py-1 px-2 -mb-3 -mt-1 rounded-xl gradient-background-two shadow-effect-one`}>
                          No Comments to display.
                        </div>
                        :
                        <div className={`text-center text-medium-gray-lighter py-1 px-2 -mb-3 -mt-1 rounded-xl gradient-background-two shadow-effect-one`}>
                          No Comments at the moment. You could be the first.
                        </div>
                      : <>
                        <div className={`text-center -mt-1 mb-6 pt-2px rounded-xl gradient-background-two shadow-effect-one text-3xl teko-font logo-style eleventh-text`}>
                          {category === 'lists'
                            ?
                            <>
                              <div className={`flex justify-center`}>
                                {numComments === 0
                                  ? <></>
                                  :
                                  <div className={`hidden sm:block`}>
                                  <button className={`eighth-text gradient-background-twelve py-2px
                                    button-ripple transition mx-px px-1 self-end font-header rounded text-xs
                                    ${commentViewSelected ? `${commentViewSelected}` : `shadow-effect-seven`}`}
                                    onClick={(e) => setView(e, 'commentView')}
                                  >
                                    <div className={`flex items-center`}>
                                      <div className={`mr-px`}>
                                        <Icon icon={commentsIcon} height={18} width={18} />
                                      </div>
                                      <div className={`ml-px mr-2px`}>View</div>
                                    </div>
                                  </button>
                                </div>
                                  
                                }
                                <div className={`mx-6 -mb-1`}>Entries ({numComments})</div>
                                {numComments === 0
                                  ? <></>
                                  :
                                  <div className={`hidden sm:block`}>
                                    <button className={`eighth-text gradient-background-twelve py-2px
                                      button-ripple transition mx-px px-1 self-end font-header rounded text-xs
                                      ${listViewSelected ? `${listViewSelected}` : `shadow-effect-seven`} `}
                                      onClick={(e) => setView(e, 'listView')}
                                    >
                                      <div className={`flex items-center`}>
                                        <div className={`mr-px`}>
                                          <Icon icon={bulletListLine} height={18} width={18} />
                                        </div>
                                        <div className={`ml-px mr-2px`}>View</div>
                                      </div>
                                    </button>
                                  </div>
                                }
                              </div>
                            </>
                            : `Comments (${numComments})`
                          }
                        </div>
                        {commentsArray.map((comment, index) => {
                          return (
                            <CommentListItemContainer
                              key={index}
                              id={comment._id}
                              comment={comment}
                              index={index}
                              postId={id}
                              post={this.props.post}
                              setPostComments={setPostComments}
                              numChildComments={comment.numChildComments}
                              setTierTwoCommentNumber={setTierTwoCommentNumber}
                              updatePostAndCommentDataAfterT2Comment={updatePostAndCommentDataAfterT2Comment}
                              refCallback={refCallback}
                              moreCommentsLoading={moreCommentsLoading}
                              noMoreComments={noMoreComments}
                              title={title}
                              postCreatorHandle={postCreatorHandle}
                              auth={auth}
                              userDatabaseData={userDatabaseData}
                              postCreatorHandCashId={postCreatorHandCashId}
                              goToUserProfilePage={goToUserProfilePage}
                              history={this.props.history}
                              toggleTipModal={toggleTipModal}
                              // Editing T1 Comments
                              updateT1CommentsArray={this.props.updateT1CommentsArray}
                              updateTier1CommentsAndPostNumbersAfterT3Creation={this.props.updateTier1CommentsAndPostNumbersAfterT3Creation}
                              listOrCommentView={listOrCommentView}
                            />
                          )
                        })
                        }
                      </>
                    }
                  </div>
                </>
              }
            </div>
          </main>
        }
      </>
    );
  }
};

export default PostDetailPage;
