import React, { Component } from 'react'
import { connect } from 'react-redux';
import { opacityChange } from '../../store/reducers/opacitySlice';
import API from '../../utils/API';
import RankingPage from './RankingPage';
import { toast } from 'react-toastify'
import { globals } from '../../config/globals';
import SkeletonLoaderRanking from '../../components/Widgets/SkeletonLoaderRanking';
import { 
  Icon,
  trophyOutline, 
} from '../../config/icons';

class RankingPageContainer extends Component {
  state = {
    listDataType: 'GET_MOST_EARNED',
    dataListOpacity: 'opacity-0',
    mostEarnedArray: [],
    mostSentArray: [],
    mostThumbsArray: [],
    mostPostsArray: [],
    mostPaywallPurchasesArray: [],
    mostFollowersArray: [],
    mostDonatedArray: [],
    selection: {
      earned: 'highlight-border',
      contributions: '',
      thumbs: '',
      posts: '',
      purchases: '',
      followers: '',
      donations: '',
    },
    rankingSelectionIsLoaded: false,
  }
  cancelToken = API.CancelToken.source();
  rankingDataListArray = ['GET_MOST_EARNED', 'GET_MOST_SENT', 'GET_MOST_THUMBS', 'GET_MOST_POSTS','GET_MOST_PAYWALLS', 'GET_MOST_FOLLOWERS', 'GET_MOST_DONATED']

  // Component Mount
  componentDidMount() {
    let dataListType = sessionStorage.getItem('rankingListSelection')
    if (dataListType && this.rankingDataListArray.includes(dataListType)) this.toggleRankingDataList('', dataListType)
    else this.toggleRankingDataList('', 'GET_MOST_EARNED')
    setTimeout(() => {
      this.props.opacityChange('addRankingPageOpacity')
    }, 100)
  }
  componentWillUnmount() {
    this.cancelToken.cancel('Operation canceled')
  };
  setListSelectionState = (value) => {
    switch (value) {
      case 'GET_MOST_EARNED':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: 'highlight-border',
            contributions: '',
            thumbs: '',
            posts: '',
            purchases: '',
            followers: '',
            donations: '',
          }
        })
        break
      case 'GET_MOST_SENT':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: '',
            contributions: 'highlight-border',
            thumbs: '',
            posts: '',
            purchases: '',
            followers: '',
            donations: '',
          }
        })
        break
      case 'GET_MOST_THUMBS':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: '',
            contributions: '',
            thumbs: 'highlight-border',
            posts: '',
            purchases: '',
            followers: '',
            donations: '',
          }
        })
        break
      case 'GET_MOST_POSTS':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: '',
            contributions: '',
            thumbs: '',
            posts: 'highlight-border',
            purchases: '',
            followers: '',
            donations: '',
          }
        })
        break
      case 'GET_MOST_PAYWALLS':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: '',
            contributions: '',
            thumbs: '',
            posts: '',
            purchases: 'highlight-border',
            followers: '',
            donations: '',
          }
        })
        break
      case 'GET_MOST_FOLLOWERS':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: '',
            contributions: '',
            thumbs: '',
            posts: '',
            purchases: '',
            followers: 'highlight-border',
            donations: '',
          }
        })
        break
      case 'GET_MOST_DONATED':
        this.setState({
          dataListOpacity: 'opacity-0',
          selection: {
            earned: '',
            contributions: '',
            thumbs: '',
            posts: '',
            purchases: '',
            followers: '',
            donations: 'highlight-border',
          }
        })
        break
      default: this.setState({
        dataListOpacity: 'opacity-0',
        selection: {
          earned: 'highlight-border',
          contributions: '',
          thumbs: '',
          posts: '',
          purchases: '',
          followers: '',
          donations: '',
        }
      })
        break
    }
  }
  toggleRankingDataList = (e, dataType) => {
    // This enables us to use this function in the componentDidMount function, and as a clickable function
    if (e) e.preventDefault();
    if (e) globals.createRipple(e)
    const currentListDataValue = this.state.listDataType;
    // Needed this extra logic for the mounting (because the values are the same), but returns out if user clicks on current selection
    if (e !== '' && dataType === currentListDataValue) return;
    let queryValue;
    // This switch statement tells our API database function which data to pull.
    // It also checks the length of the array to determine if it should query or not. If there's a length, it returns the existing data.
    this.setListSelectionState(dataType)
    sessionStorage.setItem('rankingListSelection', dataType)
    switch (dataType) {
      case 'GET_MOST_EARNED':
        if (this.state.mostEarnedArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalValueReceived';
        break
      case 'GET_MOST_SENT':
        if (this.state.mostSentArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalValueSent';
        break
      case 'GET_MOST_THUMBS':
        if (this.state.mostThumbsArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalThumbsReceived'
        break
      case 'GET_MOST_POSTS':
        if (this.state.mostPostsArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalPostsCreated'
        break
      case 'GET_MOST_PAYWALLS':
        if (this.state.mostPaywallPurchasesArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalPaywallsReceived'
        break
      case 'GET_MOST_FOLLOWERS':
        if (this.state.mostFollowersArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalFollowers'
        break
      case 'GET_MOST_DONATED':
        if (this.state.mostDonatedArray.length > 0) {
          return (
            setTimeout(() => {
              this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
            }, 400)
          )
        }
        queryValue = 'totalValueOfDonations'
        break
      default:
        return (
          globals.toastError(toast, 'Unable to retrieve list. Redirecting to home page.'),
          this.props.opacityChange('removeRankingPageOpacity'),
          setTimeout(() => {
            this.props.history.push(`/`);
          }, 300)
        )
    }
    setTimeout(() => {
      this.setState({ rankingSelectionIsLoaded: false })
    }, 300) 
    // Calling the API function
    API.getRankingData(queryValue, this.cancelToken.token)
      .then(res => {
        const queryData = res.data.handCashUserQuery;
        // Conditionally render which array the data belongs to. Then change to that sort type and add opacity
        return setTimeout(()=> {
          this.setArrayAndListDataType(dataType, queryData)
        }, 400)
      })
      .catch(error => {
        console.error(error);
        if (error.message === 'Operation canceled') return
        globals.toastError(toast, 'Unable to retrieve list. Redirecting to home page.');
        this.props.opacityChange('removeRankingPageOpacity')
        setTimeout(() => {
          this.props.history.push(`/`);
        }, 300);
      })
  }
  setArrayAndListDataType = (dataType, queryData) => {
    switch (dataType) {
      case 'GET_MOST_EARNED':
        return (
          this.setState({ mostEarnedArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({  dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_MOST_SENT':
        return (
          this.setState({ mostSentArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({ listDataType: dataType, dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_MOST_THUMBS':
        return (
          this.setState({ mostThumbsArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_MOST_POSTS':
        return (
          this.setState({ mostPostsArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_MOST_PAYWALLS':
        return (
          this.setState({ mostPaywallPurchasesArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_MOST_FOLLOWERS':
        return (
          this.setState({ mostFollowersArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      case 'GET_MOST_DONATED':
        return (
          this.setState({ mostDonatedArray: queryData, rankingSelectionIsLoaded: true, listDataType: dataType }),
          setTimeout(() => {
            this.setState({ dataListOpacity: 'opacity-100' })
          }, 400)
        )
      // It can't get to this point, because of the original switch statement. dataType parameter doesn't change. So we just return.
      default: return
    }
  }
  goToUserProfilePage = (e, userName) => {
    e.preventDefault();
    // Do an opacityChange
    this.props.opacityChange('removeRankingPageOpacity')
    setTimeout(() => {
      this.props.history.push(`/profile/${userName}`)
    }, 300)
  }
  render() {
    const { rankingPageOpacity } = this.props;
    return (
      <>
        <main id='main-content' className={`pb-3 px-1 mt-2 mx-auto w-full sm:max-w-3xl md:max-w-4xl transition`}>
          <div className={`w-full rounded-xl px-1 mb-3 first-text shadow-effect-eight`}>
            <div className={`${rankingPageOpacity} transition-to-opacity`}>
              <div className={`gradient-background-four -mx-1 rounded-t-xl`}>
              {/* Wrapping div for top */}
              <div className={`flex justify-center text-center w-auto p-2 mt-1`}>
                <div className={``}>
                  <div className={`mr-3 mb-1 inline-block align-middle text-brand-green h-8`}>
                    <Icon icon={trophyOutline} height={23} width={23} />
                  </div>
                  <h2 className={`inline-block underline text-underline-under teko-font text-4xl logo-style eleventh-text`}>Rankings</h2>
                  <div className={`ml-3 mb-1 inline-block align-middle text-brand-green h-8`}>
                    <Icon icon={trophyOutline} height={23} width={23} />
                  </div>
                </div>
              </div>
              {/* Div for the sorts */}
              <div className={`flex justify-center flex-wrap mb-1 text-sm font-header`}>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.earned} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_EARNED")}
                >
                  Earned
                </button>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.contributions} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_SENT")}
                >
                  Contributions
                </button>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.thumbs} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_THUMBS")}
                >
                  Thumbs
                </button>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.posts} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_POSTS")}
                >
                  Posts
                </button>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.purchases} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_PAYWALLS")}
                >
                  Purchases
                </button>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.followers} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_FOLLOWERS")}
                >
                  Followers
                </button>
                <button className={`m-1 py-px px-2 rounded-xl button-ripple transition cursor-pointer category-hover
                ${this.state.selection.donations} shadow-effect-seven eighth-text gradient-background-twelve`}
                  onClick={(e) => this.toggleRankingDataList(e, "GET_MOST_DONATED")}
                >
                  Donations
                </button>
              </div>
              <hr className={`sixth-border outsetBorder border mt-3 mx-2 opacity-50`}></hr>
              </div>
              {/* Data comes below hr */}
              {!this.state.rankingSelectionIsLoaded
                ? <SkeletonLoaderRanking />
                :
                // This is the wrapper div for the data
                <div className={`${this.state.dataListOpacity} transition-to-opacity`}>
                    <div className={`gradient-background-five -mx-1 pb-3 rounded-b-xl`}>
                  <RankingDataListConductor
                    {...this.state}
                    goToUserProfilePage={this.goToUserProfilePage}
                    auth={this.props.auth}
                  />
                </div>
                </div>
              }
            </div>
          </div>
        </main>
      </>
    )
  }
}
class RankingDataListConductor extends Component {
  // Rendering Switch Statement
  render() {
    switch (this.props.listDataType) {
      case 'GET_MOST_EARNED':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostEarnedArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Most Earned'}
          auth={this.props.auth}
        />;
      case 'GET_MOST_SENT':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostSentArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Contributions To Others'}
          auth={this.props.auth}
        />;
      case 'GET_MOST_THUMBS':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostThumbsArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Thumbs Received'}
          auth={this.props.auth}
        />;
      case 'GET_MOST_POSTS':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostPostsArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Most Posts'}
          auth={this.props.auth}
        />;
      case 'GET_MOST_PAYWALLS':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostPaywallPurchasesArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Content Purchase Earnings'}
          auth={this.props.auth}
        />;
      case 'GET_MOST_FOLLOWERS':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostFollowersArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Most Followers'}
        />;
      case 'GET_MOST_DONATED':
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostDonatedArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Platform Donors'}
        />;
      // Default will be the most earned, although it will never reach this point.
      default:
        return <RankingPage
          listDataType={this.props.listDataType}
          array={this.props.mostEarnedArray}
          goToUserProfilePage={this.props.goToUserProfilePage}
          dataListOpacity={this.props.dataListOpacity}
          title={'Most Earned'}
        />;
    }
  }
};

const mapStateToProps = (state) => {
  return {
    rankingPageOpacity: state.opacity.rankingPageOpacity,
    auth: state.auth.handCashLogInData
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    opacityChange: (opacityValue) => dispatch(opacityChange(opacityValue))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RankingPageContainer);