import React from 'react'
import { 
  Icon,
  thumbsUp, 
} from '../../config/icons';

const ThumbsComponent = (props) => {
  const { auth, togglePaymentModal, userAlreadyThumbsPost, postCreatorHandCashId } = props;
  return (
    <>
      {/* If user is logged in */}
      {auth?.id
        ?
        // If this is the logged-in user's post
        auth.id === postCreatorHandCashId
          ? <></>
          // If this is not the logged in user's post
          :
          <>
            {/* If the user already Thumbs'ed a post */}
            {userAlreadyThumbsPost
              ?
              <></>
              // If they have not thumbs'ed the post
              : <>
                <div className={`mx-2px mb-2`}>
                  <button className={`gradient-background-green text-white border-brand-green
                    items-center flex rounded-xl px-6px font-header button-ripple border-2 offsetBorder`}
                    onClick={(e) => togglePaymentModal(e, 'THUMBS_FOR_POST')}>
                    <div className={`-mt-px`}><Icon icon={thumbsUp} height={12} width={12} /></div><span className={`ml-2px`}>Thumb</span>
                  </button>
                </div>
              </>
            }
          </>
        :
        // If user is not logged in
        <></>
      }
    </>
  )
}

export default ThumbsComponent;